.footer {
  background: #eff2f7 0% 0% no-repeat padding-box;
  vertical-align: middle;
  height: 90px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
}



.footerLabel {
  padding: 40px;
  min-height: 86px;
  text-align: left;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0px;
  color: #334158;
}

.footer .footer-left {
  padding-left: 32px;
  display: inline-block;
  padding-bottom: 20px;
}
.footer .footer-right {
  padding-bottom: 20px;
  padding-right: 43px;
  text-align: right;
  margin-right: 70px;
}

.vertical-separator {
  vertical-align: middle;
  display: inline-block;
  height: 17px;
}
.vertical-separator > div::after {
  background: #667790;
  height: 17px;
}

.footer-button {
  /* margin: 5px 0; */
  margin-right: 2rem;
  /* height: 86px; */
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0px;
  color: #334158;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
