* {
  font-family: Arial;
  // font-size: 13px
}

////////////////////////////
/////      GENERAL     /////
////////////////////////////

@import "variables";

@media (max-width: 639px) {
  .hidden-mobile {
    display: none !important;
  }
}

@media (min-width: 640px) {
  .only-mobile {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .hidden-tablet {
    display: none;
  }
}

.display-none {
  display: none;
}

:focus,
button:focus {
  outline: none;
  outline-color: $color-white;
}

.btn:focus {
  box-shadow: none;
}

.btn:disabled {
  background-color: $color-grey;
  color: $color-grey-dark;
  border: none;
}

.btn-dismiss {
  background-color: transparent;
  border-color: transparent;
  color: $color-blue-dark;
}

.btn-primary {
  background-color: $color-blue-dark;
  border-color: $color-blue-dark;
  color: $color-white;
}

.btn-secondary {
  background-color: $color-white;
  border-color: $color-blue-dark;
  color: $color-blue-dark;
}

.ms-Dropdown-items > button {
  height: auto;
}

.ms-Dropdown:focus:after {
  border: none;
}

.SearchBox {
  margin: 15px;
  border-radius: 5px;
  border-color: $color-custom-grey;

  .ms-SearchBox-field,
  .ms-SearchBox-field::-webkit-input-placeholder {
    font-size: 0.65rem;
  }
}

// .dropDownFileType
// {
//   max-width: 400px;
//   min-width: 250px;
//   margin: 15px;
//   .ms-Dropdown-container,.ms-Dropdown-title{
//     border-radius: 5px;
//     border-color: $color-custom-grey;
//     font-size: 13px;
//     color: $color-custom-grey;
//   }
// }

/////////////////////////////
/////      CHECKBOX     /////
/////////////////////////////

.CheckboxMP {
  // position: relative;
  // display: flex;
  // flex-shrink: 0;
  // align-items: center;
  // justify-content: center;
  // height: 20px;
  // width: 20px;
  // border: 1px solid rgb(0, 120, 212);
  // border-radius: 2px;
  // box-sizing: border-box;
  // transition-property: background, border, border-color;
  // transition-duration: 200ms;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
  // overflow: hidden;
  // margin-right: 4px;
  color: rgb(0, 120, 212);

  // display: inline-block;
  // -webkit-font-smoothing: antialiased;
  // font-style: normal;
  // font-weight: normal;
  // speak: none;
  // font-family: FabricMDL2Icons;
  // opacity: 1;
  border:none;
  background: inherit;

  // i {
  //   display: inline-block;
  //   -webkit-font-smoothing: antialiased;
  //   font-style: normal;
  //   font-weight: normal;
  //   speak: none;
  //   font-family: FabricMDL2Icons;
  //   opacity: 1;
  //   color: rgb(255, 255, 255);
  // }
}

.CheckboxMP:hover {
  color: rgb(0, 90, 158);
  border-color: rgb(0, 90, 158);
}

/////////////////////////////
/////      NAV-LINK     /////
/////////////////////////////

.nav-link {
  color: grey;
  text-align: left !important;
  border: none !important;
}

.nav-link:hover {
  background-color: transparent !important;
  border: none !important;
  div {
    border-bottom: 3px solid #49505759 !important;
    height: 30px;
  }
}

.nav-link.active {
  color: #0555fa !important;
  background-color: transparent !important;
  div {
    border-bottom: 3px solid #0555fa !important;
    height: 30px;
  }
}

.nav-link.notActive {
  border-bottom: 3px solid transparent !important;
  background-color: transparent;
}

.nav-link.active:hover {
  div {
    border-bottom: 3px solid #0555fa !important;
    height: 30px;
  }
}

.nav-link.notActive:hover {
  border-bottom: 3px solid transparent !important;
  background-color: #f4f4f4;
}

//////////////////////////////
/////      MICROSOFT     /////
//////////////////////////////

.excel {
  color: $excel;
}
.word {
  color: $word;
}
.onenote {
  color: $onenote;
}
.powerpoint {
  color: $powerpoint;
}

//////////////////////////////////
/////      SECTION PANEL     /////
//////////////////////////////////

.ms-Panel-contentInner > .ms-Panel-scrollableContent {
  //overflow-x: hidden;
  //overflow-y: hidden;
}

@media (min-width: 1024px) {
  .ms-Panel-commands {
    // max-height: 10vh;
  }
}

.ms-Panel-main {
  width: 55vw;
  box-shadow: 0px 4px 16px #00000033;
  border-radius: 16px 0px 0px 0px;
}

.nestedPanel .ms-Panel-main {
  background-color: #f4f4f4;
}

.ms-Panel {
  .ms-Overlay {
    background-color: #f4f4f4a4;
  }
}

//////////////////////////////////////////////
/////       SECTION APPROVAL STATE       /////
//////////////////////////////////////////////

.rbc-event-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  //margin-left: 0px !important;
  margin-right: 5px;
  border-radius: 4px;
}

.back-planned {
  background-color: $color-planned;
}
.back-planned-status {
  color: $color-planned;
}
.back-draft {
  background-color: $color-draft;
}
.back-draft-status {
  color: $color-draft;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}
.back-inprogress {
  background-color: $color-inprogress;
}
.back-inprogress-status {
  color: $color-inprogress;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}
.back-rework {
  background-color: $color-rework;
}
.back-rework-status {
  color: $color-rework;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}
.back-approved {
  background-color: $color-approved;
}
.back-approved-status {
  color: $color-approved;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}
.back-ready {
  background-color: $color-ready-for-distribution;
}
.back-ready-status {
  color: $color-ready-for-distribution;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}
.back-distributed {
  background-color: $color-distribuited;
}
.back-distributed-status {
  color: $color-distribuited;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}
.back-closed {
  background-color: $color-closed;
}
.back-closed-status {
  color: $color-closed;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}
.back-task {
  background-color: $color-grey;
}
.back-task-status {
  color: $color-grey;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}
.back-task-completed {
  background-color: $color-success;
}
.back-task-completed-status {
  color: $color-success;
  line-height: 15px;
  font-weight: normal;
  margin-right: 8px !important;
}

// .ms-Dropdown-callout {
//   max-height: 40vh;
//   overflow-y: auto;
// }

.dropDownCaret {
  .ms-Dropdown.is-open {
    .ms-Dropdown-caretDown {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.dropDownStatus {
  height: 25px;
  margin: 5px 0px;

  .ms-Dropdown.is-open {
    .ms-Dropdown-caretDown {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .ms-Dropdown-title {
    font-size: 9pt;
    line-height: 22px;
    height: 23px;
    /* text-align: center; */
    padding-left: 20px;
  }

  .ms-Dropdown-caretDownWrapper {
    line-height: 20px;
    height: 20px;
    top: 2px;
    font-size: 9pt;

    .ms-Dropdown-caretDown {
      font-size: 8pt;
    }
  }
}

div.dropDownStatus.back-planned {
  span.ms-Dropdown-title,
  .ms-Dropdown-caretDown {
    border-color: $color-planned;
    background-color: $color-planned;
    color: $color-white;
  }

  .ms-Dropdown.is-open {
    span.ms-Dropdown-title,
    .ms-Dropdown-caretDown {
      background-color: $color-white;
      color: $color-planned;
    }
  }
}

div.dropDownStatus.back-draft {
  span.ms-Dropdown-title,
  .ms-Dropdown-caretDown {
    border-color: $color-draft;
    background-color: $color-draft;
    color: $color-white;
  }

  .ms-Dropdown.is-open {
    span.ms-Dropdown-title,
    .ms-Dropdown-caretDown {
      background-color: $color-white;
      color: $color-draft;
    }
  }
}

div.dropDownStatus.back-inprogress {
  span.ms-Dropdown-title,
  .ms-Dropdown-caretDown {
    border-color: $color-inprogress;
    background-color: $color-inprogress;
    color: $color-white;
  }

  .ms-Dropdown.is-open {
    span.ms-Dropdown-title,
    .ms-Dropdown-caretDown {
      background-color: $color-white;
      color: $color-inprogress;
    }
  }
}

div.dropDownStatus.back-rework {
  span.ms-Dropdown-title,
  .ms-Dropdown-caretDown {
    border-color: $color-rework;
    background-color: $color-rework;
    color: $color-white;
  }

  .ms-Dropdown.is-open {
    span.ms-Dropdown-title,
    .ms-Dropdown-caretDown {
      background-color: $color-white;
      color: $color-rework;
    }
  }
}

div.dropDownStatus.back-approved {
  span.ms-Dropdown-title,
  .ms-Dropdown-caretDown {
    border-color: $color-approved;
    background-color: $color-approved;
    color: $color-white;
  }

  .ms-Dropdown.is-open {
    span.ms-Dropdown-title,
    .ms-Dropdown-caretDown {
      background-color: $color-white;
      color: $color-approved;
    }
  }
}

div.dropDownStatus.back-ready {
  span.ms-Dropdown-title,
  .ms-Dropdown-caretDown {
    border-color: $color-ready-for-distribution;
    background-color: $color-ready-for-distribution;
    color: $color-white;
  }

  .ms-Dropdown.is-open {
    span.ms-Dropdown-title,
    .ms-Dropdown-caretDown {
      background-color: $color-white;
      color: $color-ready-for-distribution;
    }
  }
}

div.dropDownStatus.back-distributed {
  span.ms-Dropdown-title,
  .ms-Dropdown-caretDown {
    border-color: $color-distribuited;
    background-color: $color-distribuited;
    color: $color-white;
  }

  .ms-Dropdown.is-open {
    span.ms-Dropdown-title,
    .ms-Dropdown-caretDown {
      background-color: $color-white;
      color: $color-distribuited;
    }
  }
}

div.dropDownStatus.back-closed {
  span.ms-Dropdown-title,
  .ms-Dropdown-caretDown {
    border-color: $color-closed;
    background-color: $color-closed;
    color: $color-white;
  }

  .ms-Dropdown.is-open {
    span.ms-Dropdown-title,
    .ms-Dropdown-caretDown {
      background-color: $color-white;
      color: $color-closed;
    }
  }
}

//////////////////////////////////////////
/////      SECTION DETAILS LIST      /////
//////////////////////////////////////////

.ms-listClear {
  .ms-List-cell:nth-child(odd),
  .ms-List-cell:nth-child(even) {
    .ms-DetailsRow,
    .ms-DetailsRow:hover {
      background: $color-white;

      .ms-Button {
        background: $color-white;
      }

      .ms-Button:hover {
        background: #f4f4f4;
      }
    }
  }
}

.ms-List-cell:nth-child(even) {
  .ms-MainRow,
  .ms-MainRow:hover {
    background: #f4f4f4;
    .ms-Button {
      background: #f4f4f4;
    }

    .ms-Button:hover {
      background: $color-white;
    }
  }
}

.ms-List-cell:nth-child(odd) {
  .ms-MainRow,
  .ms-MainRow:hover {
    background: $color-white;

    .ms-Button {
      background: $color-white;
    }
  }
}

.ms-SecondaryRow:nth-child(even) {
  background: #e4e4e4;

  .ms-Button {
    background: #e4e4e4;
  }

  .ms-Button:hover {
    background: $color-white;
  }

  div[data-automationid="DetailsRow"] {
    background: #e4e4e4;
  }
}

.ms-SecondaryRow:nth-child(odd) {
  background: $color-white !important;

  .ms-Button {
    background: $color-white;
  }

  .ms-Button:hover {
    background: #e4e4e4;
  }
}

///////////////////////////////
/////      TAB PANEL      /////
///////////////////////////////
.MuiTabs-root {
  .MuiTab-wrapper {
    text-transform: none;
    div {
      display: inline-flex;

      i {
        font-size: 18px;
      }
      i,
      div {
        padding: 5px;
        line-height: 18px;
      }
    }
  }

  .Mui-selected {
    .divMui {
      border-bottom: #0152e8 solid 2px;
      color: #0152e8;
    }
  }
  .divMui {
    i[data-icon-name="Attach"] {
      transform: rotate(20deg);
    }
    > div {
      letter-spacing: 0.36px;
      font: normal normal normal 18px/32px Arial;
    }
  }

  .MuiTabs-indicator {
    background-color: #0152e8;
    display: none;
  }
}

////////////////////////////////////////////////////
/////      SECTION ACCORDION DETAILS LIST      /////
////////////////////////////////////////////////////

.MuiExpansionPanelSummary-root {
  padding: 0px !important;
}

.MuiExpansionPanelSummary-content {
  margin: 0px !important;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: inherit !important;
}

.MuiExpansionPanelDetails-root {
  padding: 0px 0px !important;
}

.MuiExpansionPanelDetails-root div[data-automation-key="activity"] {
  padding-left: 50px !important;
}

.MuiExpansionPanelSummary-expandIcon {
  transform: rotate(270deg) !important;
}

.MuiExpansionPanelSummary-expandIcon {
  transform: rotate(270deg) !important;
}

.MuiExpansionPanelSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg) !important;
}

.MuiExpansionPanel-root {
  box-shadow: none !important;
}

.MuiExpansionPanel-root::before {
  background-color: inherit !important;
}

.MuiExpansionPanelDetails-root {
  padding-left: 36px !important;
}

.MuiExpansionPanel-root {
  margin: 0px !important;
}

///////////////////////////////////
/////      ACTION BUTTON      /////
///////////////////////////////////

.action_button {
  text-align: center;
  font: normal $default-font;
  letter-spacing: 0.36px;
  color: $color-white;
  background: $color-blue 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 100%;
  // height: 40px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 25px;
  font-size: 9pt;

  i {
    font-size: 0.5rem;
  }
}
.action_button.is-disabled {
  background: #e4e4e4 0% 0% no-repeat padding-box;
  color: gray;
}

.toogleMacro {
  text-align: right;
  label {
    display: inline-block;
    color: $color-grey-dark;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    font: "Arial";
    letter-spacing: 1.12px;
    margin: 0px 12px 0px 0px;
  }
}

.action_button:hover {
  background: $color-blue-dark 0% 0% no-repeat padding-box;
  color: $color-white;
  border-radius: 4px;
}

.action_button:focus {
  outline: none;
}

//////////////////////////
/////      TYPE      /////
//////////////////////////

.typeDetails {
  max-height: 25px;
  margin: 5px;
  border: 1px solid $color-black;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  padding: 5px;
  font-size: 0.45rem;
}

.iconGet {
  font-size: 0.5rem;
  color: blue;
  cursor: pointer;
  line-height: 20px;
  padding: 5px 0px;
}

.iconType {
  font-size: 0.85rem;
}

/////////////////////////////
/////      PERSONA      /////
/////////////////////////////

.ms-Persona {
  // margin-right: -30px;

  .ms-Persona-initials {
    font-weight: 700;
  }

  // .ms-Persona-details {
  //   display: none;
  // }
}

.ms-Persona.ms-Persona--size40 {
  margin-right: -20px;
}

.ms-Persona--size40 {
  .ms-Persona-initials {
    font-size: 10pt;
    line-height: 35px;
  }
}

.ms-Persona.ms-Persona--size32 {
  margin-right: -15px;
}

.ms-Persona--size32 {
  .ms-Persona-initials {
    font-size: 8pt;
    line-height: 30px;
  }
}

.ms-Persona.ms-Persona--size24 {
  margin-right: 5px;
}

.ms-Persona--size24 {
  .ms-Persona-initials {
    font-size: 6pt;
    line-height: 22px;
  }
}

.ms-Persona.blue {
  .ms-Persona-initials {
    border: 2px solid $color-blue;
    background-color: $color-blue;
    color: $color-white;
  }
}

.ms-Persona.normal {
  .ms-Persona-initials {
    border: 2px solid $color-white;
    background-color: #d9ecf6;
    color: $color-blue;
  }
}

.timelineMacro {
  color: $color-black;
  border-radius: 4px;
  background: $color-white 0% 0% no-repeat padding-box;
  border: 1px solid $color-grey-dark;
  text-align: left;
}

.timelineMicro {
  color: $color-white;
  border-radius: 4px;
  border: 1px solid $color-grey;
  height: 24px;
  margin-top: 4;
}

.timelineTask {
  color: $color-white;
  border-radius: 4px;
  border: 1px solid $color-grey;
  height: 22px;
  margin-top: 8px;
}

:focus {
  //background-color: #b98888!important;
  //border: 2px solid #b98888!important ;
  // color: yellow;
}

h1 {
  font-size: 2rem;
  padding: 10px 50px 10px 60px;
  width: 100%;
  color: #4071cc;
  background-color:#eff2f7;
  margin-bottom: 0px;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

h7 {
  font-size: 0.8rem;
}
