.rt-element {
  $height: $react-timelines-track-height - 2 * $react-timelines-element-spacing;

  position: relative;
  //background: #06f;
  color: #fff;
  text-align: center;
}

.rt-element__content {
  padding: 0 10px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  text-overflow: ellipsis;
  width: min-content;
  min-height: 32px;

}
.rt-element-macro {
  min-height: 32px;
}
.rt-element__states {
  display: flex;
  flex-direction: row;
  height: 32px;
  align-items: center;
}

.rt-element__tooltip-line3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rt-element__tooltip-state {
  font: normal normal bold 14px/14px Arial;
  letter-spacing: 0.14px;
  color: #667790;
}
.rt-element__tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 2;
  padding: 10px;
  line-height: 1.3;
  white-space: nowrap;
  text-align: left;
  background: $react-timelines-text-color;
  color: white;
  transform: translateX(-50%) scale(0);
  pointer-events: none;

  &::before {
    $size: 6px;
    position: absolute;
    top: 100%;
    left: 50%;
    border-top: $size solid $react-timelines-text-color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
    transform: translateX(-50%);
    content: ' ';
  }
}

.rt-element:hover > .rt-element__tooltip,
.rt-element:focus > .rt-element__tooltip {
  $delay: 0.3s;
  transform: translateX(-50%) scale(1);
  transition: transform 0s $delay;
}
