.CounterComponent {
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 20px;
  padding-right: 50px;
  min-height: 300px;
}

.request_status {
  margin-bottom: 20px;
  font-size: 22px;
}

.box_counter {
  border-radius: 10px;
  background-color: #1963e9;
  padding: 10px 20px 0px 20px;
  min-height: 70px;
  cursor: pointer;
}

.state_name {
  font-size: 15px;
  font-family: Arial;
  vertical-align: middle;
  display: table-cell;
  flex-wrap: wrap;
}

.link_stat,
.link_stat:hover,
.link_stat:visited,
.link_stat:active {
  display: inline-table;
  font-family: Arial;
  color: white;
}

.title_stat {
  font-size: 20px;
  float: left;
  font-weight: 600;
  //margin: -5px 0px;
  //padding: 0px 0px;
  margin-right: 7px;
  padding: 5px;
  padding-right: 20px;
  display: table-cell;
}

.box_counter_body {
  margin-bottom: 20px;
}

.box_counter_row {
  //margin-top: 0 !important;
  color: #ffffff;
  padding-left: 0px !important;
  //   height: 80px;

  p {
    padding-left: 10px !important;
    float: left !important;
    margin-top: 20px !important;
    font-weight: 100 !important;
    line-height: 1.4rem;
    width: 82%;
  }
}

.box_counter_col {
  padding-left: 0px !important;
  padding-top: 0px !important;
  margin: 10px 0px 20px 0px;
  //   height: 60px;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
  .title_stat {
    float: left;
    font-weight: 600;
    padding: 0px 0px;
  }
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
  .title_stat {
    float: left;
    font-weight: 600;
    padding: 0px 0px;
  }
}

/* XXXL */
@media (min-width: 1366px) and (max-width: 1919px) {
}
