.pa-details {
  height: 90vh;
  top: 10vh;
  box-shadow: 0px 4px 16px #00000033;
  border-radius: 16px 0px 0px 0px;


  .ms-Panel-main {
    box-shadow: 0px 4px 16px #00000033;
    border-radius: 16px 0px 0px 0px;
  }

  .pa-details-header {
    width: 100%;
    padding: 9px 9px 24px 32px;
  }

  .pa-details-type {
    text-align: center;
    font: normal normal bold 12px/14px Arial;
    letter-spacing: 0.02rem;
    color: #667790;
    text-transform: uppercase;
    border: 1px solid #667790;
    border-radius: 4px;
    width: 70px;
    height: 40px;
    line-height: 2rem;
    float: left;
  }

  .pa-details-title {
    text-align: left;
    font: normal normal bold 28px/40px Arial;
    letter-spacing: 0.28px;
    color: #242a33;
    float: left;
    margin-left: 14px;
  }

  .pa-details-separator {
    height: 1px;
    width: 100%;
    background: #eff2f7 0% 0% no-repeat padding-box;
    margin: 1rem -20px;
  }

  .pa-details-actions {
    float: right;
  }

  
  .pa-details-actions .iconProps {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    // margin-top: -9px;
    color: #667790;
  }

  .ms-Panel-scrollableContent,
  .ms-Panel-content {
    height: 100%;
  }

  .pa-details-body {
    height: 100%;
    margin:0px;
    overflow-y: scroll;
    margin-bottom: 50px;
  }
  

  .pa-details-center {
    // max-height: 95%;
    overflow-y: hidden;
    overflow-x: hidden;
    // margin-bottom: 100px;
    border-right: 1px solid #f4f4f4;
    // margin-top: 20px;
  }

  .pa-details-right {
    max-height: 95%;
    overflow-y: auto;
    // margin-top: 20px;
    //border-left: 1px solid #f4f4f4;

    .label_RightDiv {
      color: #101AA1;
      font-weight: 500;
      padding: 10px 0px;
    }

    .value_RightDiv {
    //   color: black;
      padding: 0px 0px 10px 0px;
      width: 100%;
      flex-flow: inherit;
      display: inline-flex;
    }
  }
}

.nestedPanelActivityDetails {
  height: 90vh;
  top: 10vh;
  //width: 75vw;

  .ms-Panel-main {
    width: 75vw;

    box-shadow: 0px 4px 16px #00000033;
    border-radius: 16px 0px 0px 0px;
    background-color: #f4f4f4;
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}