@import '../../../../../../Style/variables.scss';

.rt-timebar {
  background-color: $react-timelines-keyline-color;
}

.rt-timebar__row {
  position: relative;
  height: $react-timelines-header-row-height + $react-timelines-border-width;
  overflow: hidden;
  // line-height: $react-timelines-header-row-height;
  &:last-child {
    border-bottom: $react-timelines-border-width solid $react-timelines-keyline-color;
  }
}

.rt-timebar__cell {
  position: absolute;
  text-align: center;
  
  //  left: 25px;
  background-color: $react-timelines-timebar-cell-background-color;
  border-left: 0px solid $react-timelines-keyline-color;
  border-right: 1px solid $react-timelines-keyline-color;

  .rt-timebar__cell-pointer
  {
    cursor: pointer;
  }

  .rt-timebar__cell-selected
  {
    border-color: #0152E8;
    border-style: solid;
    border-width: 1px;
    padding: 2px;
  }

  #week
  {
    position: absolute;
    top: 24px;
    left: 25px;
  }

  #yeartitle
  {
    font: 28px / 28px Arial;
  }

  #title
  {
    font: normal normal normal 28px/28px Arial;
    color: $color-black;
    letter-spacing: 0.28px;
    text-align: left;
  }
  #subtitle
  {
    font: normal normal normal 18px/18px Arial;
    color: $color-grey-dark;
    letter-spacing: 0.36px;
    text-align: left;        
    margin-top: 8px;
    text-transform: capitalize;
  };  

  #yeartitle
  {    
   position: absolute;
   top: 32px;
   text-transform: capitalize;
  };  

  #monthtitle
  {
    text-transform: capitalize;
  }

  #daytitle
  {
    text-transform: capitalize;
  }
}


