@import "../../../../Style/variables.scss";

.btnDismiss {
  padding: 5px 10px;
  border: 2px solid #0152e8;
  min-width: 140px;
  /* height: 30px; */
  min-width: 150px;
  font-size: 1rem;
}

.listContainer {
  max-width: calc(100vw - 36px);
}

.listactivities_withfilters {
  margin-top: 110px;
}

.listactivities_nofilter {
  margin-top: 65px;
}

.MenuProps {
  padding: 2px 0px;
  min-width: 10px;
}

.listActivities {
  overflow: auto hidden !important;

  .DetailsRowNoMicro {
    padding-left: 36px !important;
  }

  .divRow {
    display: inline-flex;
    // max-width: 80vw;

    .divSelection {
      display: none;
      line-height: 3rem;
      vertical-align: middle;

      label {
        width: 2rem;
        height: 2px;
        margin-bottom: 0px;
        vertical-align: middle;
      }

      label.selected {
        background-color: blue;
      }
    }

    .divInnerSelection {
      position: absolute;
      left: -50px;
      line-height: 3rem;
      vertical-align: middle;

      label {
        width: 50px;
        height: 2px;
        margin-bottom: 0px;
        vertical-align: middle;
      }

      label.selected {
        background-color: blue;
      }
    }
  }
}

.iconProps {
  width: 10px;
  height: 10px;
}

.iconStatus {
  width: 20px;
}

.DetailsCell {
  color: black;
}

.typeCell {
  border: 1px solid black;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  padding: 5px;
  margin-right: 5px;
  font-size: 0.5rem;
}

.countCell {
  border: 1px solid grey;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  padding: 5px;
  margin-right: 5px;
  font-size: 0.5rem;
  width: 25px;
}

.iconFilter {
  font-size: 9pt;
}

.refreshButton {
  color: $color-blue-dark;
}

.divResetFilter {
  text-align: right;
  color: blue;
  font-size: 10pt;
  position: fixed;
  top: 135px;
  z-index: 100;
  right: 10px;
}

.ActivityRow {
  cursor: pointer;
}

.DetailsCell {
  div[data-automationid="rangeCalendardContainer"] {
    position: relative;
  }
  div[data-automationid="rangeCalendardLabel"] > div {
    margin: 15px 15px;
  }
}

.panelActivityDetails {
  height: 90vh;
  top: 10vh;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
  .listActivities {
    div[data-automationid="DetailsHeader"] {
      padding-left: 36px !important;
    }

    overflow: auto hidden;
  }
}

@media (max-width: 1500px) {
  .DetailsCell {
    font-size: 0.7rem;
  }

  .TitleCell {
    font-size: 0.7rem;
  }

  .DetailsHeader span {
    font-size: 0.7rem;
  }
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .listActivities {
    div[data-automationid="DetailsHeader"] {
      padding-left: 35px !important;
    }

    overflow: hidden;
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
