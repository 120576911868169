@media (max-width: 639px) {
  .hiddenMobile {
    display: none !important;
  }
}

@media (min-width: 640px) {
  .onlyMobile {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .hiddenTablet {
    display: none;
  }
}

/////////////////////////////
/////      navLink     /////
/////////////////////////////

.navLink {
  color: grey;
  text-align: left !important;
  border: none !important;
}

.navLink:hover {
  text-decoration: none;
  background-color: transparent !important;
  border: none !important;
  div {
    border-bottom: 3px solid #49505759 !important;
    height: 30px;
  }
}

.navLink.active {
  color: #0555fa !important;
  background-color: transparent !important;
  div {
    border-bottom: 3px solid #0555fa !important;
    height: 30px;
  }
}

.navLink.notActive {
  border-bottom: 3px solid transparent !important;
  background-color: transparent;
}

.navLink.active:hover {
  div {
    border-bottom: 3px solid #0555fa !important;
    height: 30px;
  }
}

.navLink.notActive:hover {
  border-bottom: 3px solid transparent !important;
  background-color: #f4f4f4;
}

///////////////////////////
///      END NavLink     //
///////////////////////////

.faqs {
  // background-color: #f7f8fb;
  display: inline-grid;
  width: 100%;
  .content {
    width: 100%;
    .backgroundDiv {
      // background-color: #f7f8fb;
      width: 100%;
      // padding: 40px 60px;
    }

    .lowpanel {
      background-color: #f7f8fb;
      width: 100%;

      .panel_title {
        font-size: 24px;
        font-family: Arial;
        font-weight: bold;
        color: inherit;
      }

      .tab_pnl_home {
        // margin-top: 5px !important;
        margin-right: 0px;
      }
    }

    .view_all {
      text-decoration: underline;
      float: right;
      cursor: pointer;
      color: #0555fa;
    }

    .divFilterLanguage {
      margin: 1em 0;
      padding-left: 40px;

      .filterLanguage {
        // width: 300px;
        > span {
          border-color: #e4e4e4;
        }
        
        .IconGlobe {
          color: #0555fa;
          padding-right: 10px;
        }

        .divTextSelected {
          color: #7a899f;
          display: inline;
          font-size: 13px;
          font-family: Arial;

          .ItemSelected {
            background-color: #eff2f7;
            border-radius: 20px;
            height: 20px;
            // padding-right: 10px;
            margin: 0 5px;
            padding: 0 2px;
            display: inline-flex;
            vertical-align: text-bottom;

            .IconItemSelected {
              color: gray;
              font-size: 12px;
              margin-top: -4px;
            }
            .TextItemSelected {
              color: #0555fa;
              font-weight: 500;
              font-size: 0.5rem;
              padding: 0px 10px;
              line-height: 23px;
            }
          }
        }
      }
    }

    .tabSection {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .divSection {
        max-width: 900px;
        width: 100%;
        margin-bottom: 50px;

        .HeaderSection {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 10px;
          text-align: left;
          text-transform: capitalize;
        }

        .AccordionSection {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

@media (min-width: 767px) {
}

/* Mobile */
@media (max-width: 767px) {
}

.navTabs {
  border-bottom: 0;
  cursor: pointer;
  overflow: hidden;
  padding-left: 0;
  list-style: none;
  padding-left: 40px;

  li {
    float: left;

    a {
      padding: 10px 30px 10px 0px;
      position: relative;
      display: block;
    }
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
  .margin_top_mobile {
    margin-top: 0px !important;
  }
  .activeState {
    display: inline;
    font-size: 18px;
    float: left;
  }
  .selState {
    display: inline;
    font-size: 18px;
    float: right;
  }
  .statePanel {
    display: flow-root;
  }
  .navTabs {
    display: block;
    width: 100%;
    //padding-top: 20px;
  }
  .tab_content {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding: 40px;
  }
  .tab_pnl_home {
    width: 100%;
    min-height: 50px;
  }
  .panel_state {
    display: grid;
    .view_all {
      text-align: right;
      margin-top: 5px;
    }
  }
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .tab_pnl_home {
    min-height: 100px;
  }

  .tab_content {
    padding: 40px;
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
