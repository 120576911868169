.SupportTop {
  background-color: #f1f3f6;
  min-height: 100px;
  padding: 40px 70px;
}

.SupportBottom {
  background-color: white;
  padding: 40px;
  // position: absolute;
}

.SupportCenter {
  background-color: white;
  min-height: 150px;
}

.SupportContent {  
  margin: auto;
  max-width: 1236px;
}
