.container
{
    margin-top: 30px;
    margin-bottom: 15px;    
    overflow-y: hidden;
    overflow-x: hidden;
    // height: 80vh;
}

.row {
    width: 100%;    
    margin-top: 5px;
    
    .content
    {
        display: inline-block;
    }
    
    .content50
    {        
        @extend .content;
        width: 50%;
    }

    .content100
    {        
        @extend .content;
        width: 100%;
    }

    .left {
        text-align: left;
    }

    .right {
        text-align: right;
    }

    .contentText
    {     
        font: normal normal normal 12px/16px Arial;
        letter-spacing: 0.12px;
        color: #667790;
        opacity: 1;
    }
}


.rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
  

  