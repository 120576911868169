
.PanelActivityHeaderComponent {
  margin: 0px 20px;
  width: 100%;

  .MenuProps {
    padding: 10px 0px;
  }

  .TitleDetails {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .statuslabelDetails {
    margin: 5px 10px;
  }

  .typeDetails,
  .TitleDetails,
  .statusChangeDetails,
  .contextMenuHeaderDetails {
    padding-right: 5px;
    padding-left: 5px;
  }

  .optionDropdownStatusChange {
    font-size: 9pt;
  }

  .contextMenuHeaderDetails {
    text-align: center;
    .iconContextMenuDetails {
      height: 15px;
      width: 15px;
      margin: 10px;
    }
  }

  .manageActivity {
    min-width: 140px;
    font-size: 0.7rem;
  }

  .activityNameActions {
    color:blue;
    display: inline-flex;
    text-align: right;
    width: 100%;
    flex-flow: row-reverse;

    i {
      padding: 0px 5px;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
  .PanelActivityHeaderComponent {
    .statusChangeDetails {
      width: 100%;
      display: flex;
    }
  }
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .PanelActivityHeaderComponent {
    flex-flow: inherit;

    .TitleDetails {
      width: 100%;
    }

    .statusChangeDetails {
      display: inline-flex;
    }
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
