@import "../../../../Style/variables.scss";

.month-calendar-container {
  font-family: Arial;
  font-size: 0.8rem;
  background-color: $color-white;
  color: $color-black;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.month-calendar-header {
  text-align: center;
  font-size: 0.6rem;
  color: $color-grey-dark;
  padding-top: 8px;
  position: relative;
}

.month-calendar-header:not(.month-calendar-header--has-time-select) {
  border-top-right-radius: 0.3rem;
}
.month-calendar-monthname {
  margin-bottom: 20px;
  text-transform: capitalize;
}

.month-calendar-monthname,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: $color-black;
  font: normal normal normal 18px/28px Arial;
  letter-spacing: 0.36px;
}

.month-calendar-monthname a {
  color: $color-black;
}

.month-calendar-monthname-current {
  color: $color-blue;
}

.month-calendar-day-header-names {
  margin-bottom: 14px;
}

.month-calendar-day-header-names,
.month-calendar-week-container {
  white-space: nowrap;
}
.month-calendar-day-name {
  display: inline-block;
  width: 32px;
  text-align: center;
  font: normal normal normal 12px/16px Arial;
  letter-spacing: 0.12px;
  color: $color-grey-dark;
  opacity: 0.4;
  margin: 0 5px;
  text-transform: capitalize;
}

.month-calendar-dayinternal {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font: normal normal normal 18px/28px Arial;
  letter-spacing: 0.36px;
}

.month-calendar-day-number {
}

.month-calendar-day-number a {
  color: $color-grey-dark;
}

.month-calendar-days-container {
  margin: 0 5px;
  text-align: center;
}

.month-calendar-day {
  color: $color-grey-dark;
  display: inline-block;
  width: 32px;
  text-align: center;
  margin: 0 5px;
}

.month-calendar-day a {
  color: $color-grey-dark;
}

.month-calendar-today {
  // color: $color-white;
  // background-color: $color-blue;
  // border-radius: 4px;
  // border-bottom-width: 0px;
  // text-align: center;
  // margin: auto;
  color: $color-blue;  
  border-radius: 4px;
  border-bottom-width: 0px;
  text-align: center;
  margin: auto;
}

.month-calendar-selected-day {
  
  border-style: solid;
}

.month-calendar-day-pointer {
  cursor: pointer;
}

.cicle-container {
  margin-top: -4px;
  line-height: 8px;
}

.circle-yes-activities {
  height: 8px;
  width: 8px;
  background-color: $color-blue;
  border-radius: 50%;
  display: inline-block;
}

.circle-no-activities {
  height: 3px;
  width: 3px;
  background-color: #c5e801;
  border-radius: 50%;
  display: inline-block;
  visibility: hidden;
}

.circle-status {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.back-planned {
  background-color: $color-planned;
}
.back-draft {
  background-color: $color-draft;
}
.back-inprogress {
  background-color: $color-inprogress;
}
.back-rework {
  background-color: $color-rework;
}
.back-approved {
  background-color: $color-approved;
}
.back-ready {
  background-color: $color-ready-for-distribution;
}
.back-distributed {
  background-color: $color-distribuited;
}
.back-closed {
  background-color: $color-closed;
}

.activityThinDetail {
  font-weight: bold;
}

.tooltip-activity {
  margin: 16px 5px;
  font: normal normal bold 14px/14px Arial;
  letter-spacing: 0.14px;
  min-width: 250px;
}


/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}