.multiplefileupload-container
{
    width: 100%;
}

.center
{
    text-align: center;
}

.multiplefileupload-icon
{
    @extend .center;
}

.multiplefileupload-title
{
    @extend .center;
    
    font: normal normal bold 28px/40px Arial;
    letter-spacing: 0.28px;
    color: #242A33;
    opacity: 1;
}

.multiplefileupload-subtitle
{
    @extend .center;
    font: normal normal normal 18px/32px Arial;
    letter-spacing: 0.18px;
    color: #242A33;
    opacity: 1;
}

.multiplefileupload-button
{
    @extend .center;
}

.multiplefileupload-hint
{
    @extend .center;
    font: normal normal normal 12px/16px Arial;
    letter-spacing: 0.12px;
    color: #667790;
    opacity: 1;
}

.divRemove
{
    &:hover {
        cursor: pointer;
    }
}