@import '../../../Style/variables.scss';

.calendardContainer
{
    width: 100%;
}

.customCalendardContainer
{    
    background-color: $color-blue-light; 
    display: grid;
    grid-template-columns: auto auto;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    background-color: $color-white;
    border: thin solid $color-grey;
    margin-top: 2px;
    > div
        {
            text-align:center;
        }
}

.customCalendard-toolbar
{
    grid-column: 1 / span 2;
    text-align: right !important;
    margin-top: -5px;
    border-top: solid thin $color-grey-light;

    div:nth-child(1)  {
        float: left;    
    }
    div:nth-child(2)  {
        float: right;    
    }
}

 .customCalendardContainer [type="text"]
 {
     cursor: pointer;
     font-size: small;
 }
  .ms-DatePicker-wrap {
    margin: -1px;
    padding-top: 0px;
    padding-bottom: 0px;
   }

   .timeContainer {
    font-size: xx-small;
    padding-left: 2px;
    padding-right: 19px;
    display: flex;
    flex-wrap: nowrap;
    background-color: $color-white;
    width: 100%;
  }
  
  .timeContainer > div {
    background-color: $color-white;    
    margin: auto;
    text-align: center;    
  }

  .timeContainer > div:nth-child(1)
  {
      width: 40%;
      text-align: left;
  }
  .timeContainer > div:nth-child(2)
  {
      width: 5%;
  }
  .timeContainer > div:nth-child(3)
  {
      width: 10%;
  }
  .timeContainer > div:nth-child(4)
  {
      width: 5%;
  }
  
