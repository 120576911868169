@import '../../../../Style/variables.scss';

.brief-detail-container
{
    // overflow-y: auto !important;
    // margin-top: 50px;
    // padding-top: 30px;
    // border-top-style: solid;
    // border-top-width: 1px;
    // border-top-color: $color-grey;

    
    // margin: 10px;
    height: 75vh;
    overflow-y: auto;
    
}

.panel-container {
    // height: 90vh;
    // top: 10vh;
    // overflow-y: auto !important;
}

.brief_details_separator {
    margin: 1rem -20px;
  }

.panel-header
{ 
    width: 100%;
    margin-left: 30px;
    flex-flow: inherit;
    display: flex;
    height: 35px;
}

.panel-header-title
{
    text-align: left;
    font: normal normal bold 28px/40px Arial;
    letter-spacing: 0.28px;
    color: $color-black;
    opacity: 1;
    width: 100%;
}

.panel-header-menu
{
    padding-right: 5px;
    padding-left: 5px;
    margin: auto;
}