.SupportHeaderComponent {
  margin: auto;
  max-width: 1236px;
}

.IconBackground {
  position: absolute;
  right: 5%;
  top: 0;
  // z-index: 1;
  opacity: 0.5;

  i {
    z-index: -1;
    font-family: FabricMDL2Icons-4;
    color: #fefefe;
    font-weight: normal !important;
  }

  i.PDF,
  i.OfficeChat {
    font-size: 200pt;
    transform: rotate3d(x, y, z, angleValue);
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
    margin-top: -20px;
  }

  i.MSNVideos {
    font-size: 200pt;
  }
}

.buttonSupport {
  width: 170px;
  padding: 0px 5px;
  border-radius: 5px;
  border: 1px black solid;
  color: white;
  font-family: inherit;
  font-size: 12px;
  cursor: pointer;
  background-color: #0f51f9;

  display: flex;
}

.buttonSupport div {
  padding: 5px;
  line-height: 25px;
}

.iconBack {
  padding-right: 10px;
  font-weight: 600 !important;
  font-size: 12px;
  padding-top: 4px;
  color: #0152e8;
}

.textBack {
  color: #0152e8;
  font-weight: 600 !important;
}

.buttonSupport:hover {
  background-color: rgb(13, 73, 225);
  border: 1px solid rgb(13, 73, 225);
  color: rgb(255, 255, 255);
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  padding: 0px;
}

.gridItem {
  padding: 0px;
  display: inline-flex;
  cursor: pointer;
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}

.Heading {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
.Text {
  font-size: 15px;
  font-weight: 600 !important;
  padding-bottom: 20px;
  min-height: 90px;
  position: relative;
  z-index: 2;
}
