.welcome {
  width: 100%;
  padding: 20px 50px 20px 50px;

    // padding-left: 5px;
    // margin: 20px;
    // height: 380px;
  vertical-align: middle;
}
.welcome h1 {
  // font-size: 48px;
  font-family: Arial;
  font-weight: 100;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
  .welcome h1 {
    font-size: 2rem;
  }
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
  .welcome h1 {
    font-size: 3rem;
  }
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
