.NoteComponent {
  .ActivityNotes {
    // margin-top: 20px;
    // padding: 0px;

    .divIconNotes {
      background: #0047cc;
      padding: 8px 15px;
      border-radius: 200px;
      cursor: pointer;
      position: fixed;
      right: 80px;
      bottom: 20px;

      i {
        font-size: 2rem;
        color: white;
      }
    }

    .divIconWithNotes {
      background: #FE1E6D;
      padding: 6px;
      border-radius: 100px;
      cursor: pointer;
      position: fixed;
      right: 126px;
      bottom: 65px;
    }
  }
}

:global .PanelActivityNotes {
  .ms-Panel-main {
    width: 55vw;
    // height: 125px;
    inset: auto 0px 0px auto;

    .ms-Panel-scrollableContent {
      height: 100%;

      .ms-Panel-content {
        padding: 0px;
        height: 100%;
        background-color: #f4f4f4;
      }
    }

    .ms-Panel-commands {
      display: none;
    }
  }
}
.PanelActivityNotes {
  .formActivityNotes {
    background-color: #f4f4f4;
    width: 100%;
    height: 100%;
    padding: 20px 20px 15px 20px;

    .headerActivityNotes {
      margin: 0px;
      flex-flow: inherit;

      .accordionHeaderActivityNotes {
        width: 100%;
        line-height: 1.25rem;
        color: #0152e8;
        font-weight: 500;
        cursor: pointer;
        padding: 10px;

        i {
          padding-left: 10px;
          font-size: 0.6rem;
        }
      }

      .dropdownoHeaderActivityNotes,
      .labelHeaderActivityNotes {
        padding: 10px;
      }
    }

    .bodyActivityListNotes {
      max-height: 65vh;
      overflow-y: auto;

      .noteItem {
        padding: 10px;
        font-size: 0.75rem;

        .headerNote {
          flex-flow: inherit;
          display: inline-flex;
          width: 100%;

          .personNote {
            padding-right: 20px;
          }

          .displayNameNote {
            width: 100%;
            line-height: 2rem;
          }
          .dateNote {
            min-width: 150px;
            text-align: right;
            color: #a4a4a4;
            line-height: 2rem;
          }
        }
        .textNote {
          font-size: 0.8rem;
          padding-top: 10px;
          white-space: pre-wrap;
        }

        .NoteActions {
          display: inline-flex;
          text-align: right;
          width: 100%;
          flex-flow: row-reverse;

          i {
            padding: 0px 5px;
            font-size: 0.8rem;
            cursor: pointer;
          }
        }
      }

      .ms-Persona {
        .ms-Persona-initials {
          font-size: 8pt;
          line-height: 2rem;
        }
      }
    }

    .bodyActivityNotes {
      margin: 0px;
      flex-flow: inherit;

      .userBodyActivityNotes,
      .textBodyActivityNotes {
        padding: 10px 15px;
      }

      .textBodyActivityNotes {
        width: 100%;

        .textActivityNotes {
          padding: 0px 0px;
          background-color: white;
          border: 1px #c8d2e0 solid;
          width: 100%;
        }

        .textActivityNotesActions {
          display: inline-flex;
          width: 100%;
          flex-flow: row-reverse;

          i {
            padding: 0px 10px;
            cursor: pointer;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
