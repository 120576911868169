.rt-sidebar {
  background-color: $react-timelines-sidebar-background-color;
  //box-shadow: 10px 0 10px -5px rgba(12, 12, 12, 0.1);
  border-right: 1px $react-timelines-sidebar-separator-color solid;
}

.rt-sidebar__header {
  background-color: $react-timelines-sidebar-background-color;
  height: $react-timelines-header-row-height;
  border-bottom: 1px $react-timelines-sidebar-separator-color solid;
}

.rt-sidebar_search
{
  padding: 24px 32px 24px 28px;
}

.rt-sidebar__header.rt-is-sticky {
  position: fixed;
  top: 55px;
  z-index: 2;
  direction: rtl;
  margin-left: ($react-timelines-sidebar-width - $react-timelines-sidebar-closed-offset);
  margin-top: 80px;

  @media (min-width: $react-timelines-auto-open-breakpoint) {
    margin-left: 0;
    direction: ltr;
  }
}

.rt-sidebar__header.rt-is-sticky-with-filters {
  position: fixed;
  top: 110px;
  z-index: 2;
  direction: rtl;
  margin-left: ($react-timelines-sidebar-width - $react-timelines-sidebar-closed-offset);
  margin-top: 80px;

  @media (min-width: $react-timelines-auto-open-breakpoint) {
    margin-left: 0;
    direction: ltr;
  }
}

.rt-layout.rt-is-open .rt-sidebar__header.rt-is-sticky {
  margin-left: 0;
  direction: ltr;
}

.rt-sidebar__body {}
