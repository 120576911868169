@import "";

.navigation {
  text-align: right;
}

.hamburger-react {
  position: inherit!important;
}

li.menuitem  {
  list-style: none;    
  font-size: 17px!important;
  color: white;
  padding: 2px 0px;
  cursor: pointer;
}

.subMenu {
  padding-left: 25px;
  color: white;
  /* margin-left: 5px; */
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 2px;
}

.subMenuButton {
  font-size: 14px!important;
  /* height: 30px!important; */
}

.pivotControl button {
  padding: 2px 8px 3px;
  height: 25px;
  cursor: pointer;
  text-align: center;
  background-clip: padding-box;
  overflow: hidden;
  border: 1px solid transparent !important;
  margin-right: 0px;
  color: rgb(243 242 241 / 60%);
  font-size: 18px;
}

.pivotControl button:hover {
  border-color: #fff;
  color: #fff;
  background-color: rgb(243 242 241 / 0%);
}
.pivotControl button:hover:before {
  background-color: #fff;
  height: 1px;
  width: 33%;
  left: 33%;
}

.pivotControl button[aria-selected="true"] {
  border-color: #fff;
  color: #fff;
}
.pivotControl button[aria-selected="true"]:before {
  background-color: #fff;
  height: 1px;
  width: 33%;
  left: 33%;
}

.navControl {
  width: 190px;
}

.navControl button {
  width: 150px;
  /* padding-bottom:10px ; */
  height: 35px;
  cursor: pointer;
  text-align: left;
  /* background-clip: padding-box; */
  overflow: hidden;
  border: 1px solid transparent !important;
  margin-right: 0px;
  color: white;
  font-size: 18px;
  background-color: #0152e8;
}
.navControl button:hover {
  border-color: #fff;
  color: #fff;
  /* background-color: rgb(243 242 241 / 0%); */
}
.navControl button:hover:before {
  background-color: #fff;
  height: 1px;
  width: 33%;
  left: 33%;
}

.navControl button[aria-selected="true"] {
  border-color: #fff;
  color: #fff;
}
.navControl button[aria-selected="true"]:before {
  background-color: #fff;
  height: 1px;
  width: 33%;
  left: 33%;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1130px) {
  .hamburger-react {
    /* color: green !important; */
    display: inline !important;
    /* float: right;        */
  }

  .pivotControl {
    display: none;
  }

  
}

.hamburger-react {
  display: none;
  color: white;
}

.pivotControl {
  padding-top: 10px;
  margin-right: 63px;
}

.pivotControl.noMargin {
  margin-right: 20px;
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
