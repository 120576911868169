.HomeCards {
  width: 100%;

  .HomeCard {
    z-index: 2;
    height: 150px;
    border-top: 10px solid #0f51f9;
    border-radius: 8px;
    -webkit-box-shadow: 7px 7px 13px #70707047;
    box-shadow: 7px 7px 13px #70707047;

    padding: 20px 0px;
    text-align: center;
    // height: 120px;
    cursor: pointer;
    background-color: white;

    .iconCardContainerSupportHome {
      background-color: #f1f3f6;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      padding: 8px;
      display: inline-grid;

      .iconCardSupportHome {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2rem;
        margin-top: -8px;
        margin-left: 3px;
      }
    }

    .lblCardContainerSupportHome {
      .lblCardSupportHome {
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 10px;
        margin-left: 3px;
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}

@media (min-width: 768px) {
  .HomeCards {
    // position: absolute;
    display: inline-flex;
    margin: -50px 0px;

    .HomeCard {
      margin: 0 2%;
      width: 25%;
    }
  }
}

@media (max-width: 767px) {
  .HomeCards {
    display: inline-grid;
    margin: -50px 0px 50px 0px;
    .HomeCard {
      margin: 10px 10%;
      width: 80%;
    }
  }
}
