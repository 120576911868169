.user-container {    
    // margin-bottom: 25px;
    padding: 10px 10px 10px 10px !important;
    border: 0;
    // box-shadow: 0 13px 34px 0 rgba(1, 13, 38, 0.05);
    background-color: white !important;
    // border-radius: 20px;
    // box-shadow: 7px 7px 13px #70707024;
    font: normal normal normal 12px/16px Arial;
    width: 15.5rem;
     
    .user-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      font-family: Arial;
      color: black !important;
    }

    .user-displayname
    {      
      align-items: center;
      display: inline-grid;
      grid-template-columns: auto auto;      
      font-weight: bold;
      background-color: white !important;  
      .user-persona
        {          
          margin-left: -5px;          
        }

    }

    .user-calendar
    {
      padding: 0.63rem;
    }

    .user-role
    {
      margin-top: 15px;         
      width: 100%;
      font-style: italic;
      // border-bottom-style: solid;
      // border-bottom-width: 1px;
      > span
      {
        padding-top: 10px;
        font: normal normal normal 12px/16px Arial;
        font-weight: bold;
        font-style: italic;
      }
    }

    .user-contacts
    {
      padding-top: 10px;
      width: 100%;
      color: blue;      
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }   
    .user-faq
    {
      width: 100%;
      color: blue;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }  
    .user-whoweare
    {
      width: 100%;
      color: blue;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }   
    .user-logout
    {
      width: 100%;
      color: blue;
      cursor: pointer;
      text-decoration: underline;          
    }
  
    .card_body_header {
      display: inline-grid;    
      grid-template-columns: auto auto;
      padding-top: 0px;
      padding-right: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
      font: normal normal normal 14px/18px Arial;
      letter-spacing: 0.12px;
      // color: #667790;
      opacity: 1;
  
      .card_time
      {
        text-align: left;           
      }
  
      .card_date
      {
        text-align: right;      
      }
    }
  
    .card_body_content {
      height: 100%;
      padding: 0px 0px;
      font-family: Arial;
      color: black !important;
      .card_user
      {
        align-items: center;
        display: inline-grid;
        grid-template-columns: auto auto;
        .card_user_persona
        {
          margin-right: 5px;
          margin-left: -5px;
        }
      }
    }
  
    .card_title {
      text-align: left;
      font: normal normal bold 12px/14px Arial;
      letter-spacing: 0.12px;
      color: #242A33;
      opacity: 1;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  
    .card_typestatus {
      text-align: left;
      font: normal normal normal 12px/16px Arial;
      letter-spacing: 0.12px;
      color: #667790;
      opacity: 1;
      margin-bottom: 5px;
    }
  
    .cardState {
      padding: 0px;
      margin-top: 0px !important;
      margin-bottom: 0;
      padding-left: 0px !important;
      padding-right: 0px !important;
      font-size: 13px;
      font-weight: bold;
  
      .rbc_event_dot {
        margin-left:  0px;
        margin-right: 10px;
      }
    }
  
    .dateLabel {
      font-size: 11px;
      // margin-top: 0px;
      margin-top: 5px;
      margin-bottom: 4px !important;
    }
  
    .truncate_overflow_parent {
      cursor: pointer;
      overflow: hidden;
      position: relative;
      text-align: left;
      // margin-right: -1em;
      // padding-right: 1em;
      // width: 265px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  
    .truncate_overflow_child {
      cursor: pointer;
      overflow: hidden;
      text-align: left;
      padding-right: 1em;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      padding: 0px;
      font-weight: 600;
      margin-top: 4px;
      font-size: 13px;
      line-height: 1.2em;
      max-height: 2.4em;
      height: 2.4em;
      margin-bottom: 5px;
    }
  }
  
  .activityCard:hover {
    box-shadow: 7px 7px 13px #70707047;
    cursor: pointer;
  }
  
  @media (max-width: 767px) {
    .cardState {
      width: 100%;
    }
  }
  
  .card_text.card_greytext span.bold {
    color: black;
  }

  
.ms-Panel-contentInner > .ms-Panel-scrollableContent {
  overflow-x: auto;
  overflow-y: auto;
}
  
  