.rt-timeline {
  position: relative;
  overflow: hidden;
}

.rt-timeline__header {
  border-top: 1px solid $react-timelines-sidebar-separator-color;
}

.rt-timeline__header-scroll {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.rt-timeline__header.rt-is-sticky {
  position: fixed;
  top: 55px;
  z-index: 100;
  overflow: hidden;
  margin-top: 80px;
}

.rt-timeline__header.rt-is-sticky-with-filters {
  position: fixed;
  top: 110px;
  z-index: 100;
  overflow: hidden;
  margin-top: 80px;
}

.rt-timeline__body {
  position: relative;
  background: white;
}
