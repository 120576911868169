.rt-timebar-key {
  height: $react-timelines-header-row-height + $react-timelines-border-width + 1;
  padding-right: $react-timelines-sidebar-key-indent-width;
  line-height: $react-timelines-header-row-height;
  text-align: right;
  font-weight: bold;
  //border-bottom: 1px solid $react-timelines-sidebar-separator-color;

  &:last-child {
    //border-bottom-color: $react-timelines-header-separator-color;
    border-bottom: 1px solid $react-timelines-sidebar-separator-color;
  }
  &:first-child {
    border-top: 1px solid $react-timelines-sidebar-separator-color;
  }
}
