@import '../../../../../../Style/variables.scss';

.DeliverableComponent {
  .headerDeliverables {
    display: inline-flex;
    // margin-top: 30px;

    span {
      padding-right: 20px;
      font-weight: 500;
      line-height: 1.5rem;
      font-size: 1.25rem;
    }

    div {
      padding: 0px 5px;
      background-color: #f4f4f4;
      color: blue;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5rem;
      cursor: pointer;
    }
  }

  .divDeliverablesList {
    // margin-top: 20px;
    // height: 20vh;
    // max-height: 20vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5px;

    .deliverablesDetailsCell {
      color: black;
      display: inline-flex;
      font-size: 0.85rem;
      .text {
        padding: 0px 10px;
      }
    }

    .iconProps {
      width: 10px;
      height: 10px;
    }

    .DeliverablesRow {
      cursor: pointer;
    }
  }

  .deliverablesSelection {
    margin: 10px;
    .deliverablesSelectionLabels {
      text-align: right;
      display: grid;

      .deliverablesSelected {
        font-size: 0.85rem;
      }

      .deliverablesSelectionSize {
        font-size: 0.5rem;
      }
    }

    .SelectAll {
      display: inline-flex;
      cursor: pointer;
      .iconType {
        padding: 5px 10px 5px 1px;
      }
      .text {
        padding: 5px 10px 5px 5px;
      }
    }
  }
}

.modalContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  min-width: 40vw;
  border-radius: 10px;
}

.modalHeader {
  flex: 1 1 auto;
  color: rgb(50, 49, 48);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 12px 0px 24px;
  margin-top: 18px;
}

.modalBody {
  flex: 4 4 auto;
  padding: 24px 32px;
  overflow-y: hidden;
  .selectors {
    p: {
      margin: 14px 0;
    }
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.modalFooter {
  flex: 1 1 auto;
  color: rgb(50, 49, 48);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 50px 12px 40px;
  text-align: right;

  div {
    display: inline;
  }
}

.ModalButton {
  color: rgb(50, 49, 48);
  margin-left: auto;
  margin-top: 4px;
  margin-right: 2px;
}

.ModalButton:hover {
  color: rgb(32, 31, 30);
}

.ModalFooterRow {
  width: 100%;
  margin-left: 0px;
  flex-flow: inherit;
}

.divBtnNewFile {
  min-width: 150px;

  button {
    width: 150px;
  }
}

.Label {
  padding-top: 10px;
}

.fileTypeOption {
  i {
    padding-right: 10px;
    font-size: 1.25rem;
  }
}

.divDeliverableVersionsList {
  // margin-top: 20px;
  // height: 20vh;
  // max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;

  .deliverableVersionsDetailsCell {
    color: black;
    display: inline-flex;
    font-size: 1rem;
    .text {
      padding: 0px 10px;
    }
  }

  .iconVersionProps {
    width: 10px;
    height: 10px;
  }

  .DeliverablesVersionsRow {
    cursor: pointer;
  }
}

.imgPreview {
  max-height: 60vh;
  max-width: 100%;
}

.embedPreview {
  min-height: 60vh;
}

.modalContainerPreview { 
  width: 70vw;
  height: 100vh;
}

.modalBodyPreview {
  text-align: center;
  min-height: 80%;
}

.modalContainerFileOverwrite { 
  width: 500px;
  height: 250px;
  min-height: 250px;
  min-width: 500px;
}

.modalBodyFileOverwrite {
  padding: 5px 32px;
  height: 100px;
}

.errorFileInsert {
  color: $color-error
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
  .DeliverableComponent {
    div[data-automationid="DetailsList"] {
      div[role="grid"] {
        overflow-x: auto;
      }
    }
  }
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .DeliverableComponent {
    div[data-automationid="DetailsList"] {
      div[role="grid"] {
        overflow-x: hidden;
      }
    }
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
