.ActivitySidebarComponent {
  .assignedResources {
    width: 100%;
  }

  .label_RightDiv {
    color: black;
    font-weight: 500;
    padding: 10px 0px;
  }

  .value_RightDiv {
    color: black;
    padding: 0px 0px 10px 0px;
    width: 100%;
    flex-flow: inherit;
    display: inline-flex;

    input {
      font-size: 15px;
    }
  }

  .ActivityDescription {
    color: blue;
    // font-weight: bold;
    cursor: pointer;
    text-align: justify;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    padding-bottom: 0px;
    margin-bottom: 10px;    
    padding-right: 20px;
    width: 100%;
  }

  .editableField {
    // color: blue;
    cursor: pointer;
    flex-flow: inherit;
    display: inline-flex;
    width: 100%;

    .text {
      width: 100%;
    }
  }

  .parentActivity {
    color: blue;
    font-weight: bold;
    cursor: pointer;

    div {
      margin-left: 0px;
      // text-align: center;
      display: inline-block;
      padding: 5px;
    }
    .text {
      width: 100%;
    }
  }

  .MacroDocumentation {
    color: blue;
    font-weight: bold;
    cursor: pointer;
    display: inline-flex;
    i {
      font-size: 0.65rem;
      padding: 1px 2px;
    }
    div {
      padding: 0px 10px;
      width: 100%;
    }
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}