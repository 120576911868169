.RichTextboxCustom {
    min-height: 200px;
}

.newbrief-container
{
    display: flex;
    width: 100%;
}

.newbrief-container-item-left
{
    flex: 80%;    
}

.newbrief-skip-container
{
    margin-top: 35px;
    margin-bottom: 35px;
}

.newbrief-container-item-right
{
    flex: 20%;
    text-align: right;
    position: relative;
    top: 0px;    
    margin-left: 50px;
}

.newbrief-button-top
{
    margin-top: 30px;    
}

.newbrief-button-bottom
{    
    margin-bottom: 15px;
}

.row-space
{
    margin-bottom: 10px !important;

}

.enable-delegate
{
    margin-top: 40px;
}

.scroll-y
{
    overflow-y: scroll;
    padding-bottom: 50px;
    height: 80vh;
}