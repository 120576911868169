.nestedPanelActivityDetails {
  height: 90vh;
  top: 10vh;
}

.ActivityDetailsComponent {
  .activity_details_separator {
    margin: 1rem -20px;
  }

  .activity_details_body {
    margin: 10px;
    height: 75vh;
    overflow-y: auto;
  }

  .activity_details_parent_body {
    margin-left: 10px;
    margin-right: 50px;
    margin-bottom: 50px;
  }

  .activity_details_center {
    // max-height: 95%;
    overflow-y: hidden;
    overflow-x: hidden;

    .ActivityComponents {
      overflow-x: hidden;
      overflow-y: auto;
      margin-bottom: 100px;
    }
  }

  .activity_details_left {
    overflow-y: auto;
    border-right: 1px solid #f4f4f4;
  }

  .headerActivityDetails {
    .toggleValidation,
    .sendForApproval {
      min-width: 200px;
      padding: 0px 10px;
    }
  }

  .toggleValidation {
    margin: 2px 0px;
    text-align: right;

    label {
      font-size: 9pt;
      color: #e4e4e4;
      margin: 0px 1px;
    }

    button {
      background-color: transparent;
    }

    button:hover {
      background-color: transparent;
    }

    button[aria-checked="false"] {
      border-color: #e4e4e4;

      span {
        background-color: #e4e4e4;
      }
    }

    button[aria-checked="true"] {
      border-color: #e4e4e4;
      span {
        background-color: #0152e8;
      }
    }

    // button:hover {
    //   border-color: #0152e8;
    // }
  }

  .toggleValidation.is-checked,
  .toggleValidation.is_checked {
    label {
      font-size: 9pt;
      color: #0152e8;
    }
  }

  .historyDropdown {
    width: 100%;

    div:first-child {
      // width: 100%;

      span:first-child {
        height: auto;
        white-space: break-spaces;
        line-height: 1.75rem;
        min-height: 2rem;
      }
    }

    div[aria-expanded="true"] {
      .ActionTitleClosed {
        display: none;
      }
      .ActionTitleExpanded {
        display: flex;
        strong {
          padding: 5px 0px;
          color: gray;
          height: 100%;
          text-transform: uppercase;
        }
      }
    }

    div[aria-expanded="false"] {
      .ActionTitleClosed {
        display: flex;
      }
      .ActionTitleExpanded {
        display: none;
      }
    }
  }
  .descriptionEditor {
    flex-flow: inherit;
    display: inline-flex;
    width: 100%;
  }

  .descriptionText {
    height: 40vh;
    .labelDescription {
      padding: 0px;
    }

    .textDescription {
      background-color: white;
      border: 1px #c8d2e0 solid;
      width: 100%;
      // height: auto;
      // height: 40vh;
      overflow-y: auto;
      min-height: 15rem;

      div:before {
        border: none;
        max-height: 40vh;
      }
    }

    textarea {
      min-height: 200px;
      max-height: 600px;
      overflow-y: auto !important;
    }
  }

  .descriptionActions {
    color: blue;
    display: inline-flex;
    text-align: right;
    width: 100%;
    flex-flow: row-reverse;

    i {
      padding: 0px 5px;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .descriptionEdit {
    color: blue;
    display: inline-flex;
    text-align: right;
    flex-flow: row-reverse;

    i {
      padding: 0px 5px;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .editableField {
    // color: blue;
    cursor: pointer;
    flex-flow: inherit;
    display: inline-flex;
    width: 100%;

    .text {
      width: 100%;
    }
  }
}

span.author {
  color: black;
}

span.date {
  font-weight: 600;
}

.viewDetailsAction {
  margin-left: 15px;
}

.iconAction {
  margin-left: 0px !important;
  margin-right: 10px !important;
}

.modalContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 60vw;
  border-radius: 10px;

  .modalHeader {
    flex: 1 1 auto;
    color: rgb(50, 49, 48);
    display: flex;
    align-items: center;
    padding: 0px 24px 0px 0px;
    margin-top: 18px;
  }

  .modalBody {
    flex: 4 4 auto;
    padding: 24px 32px;
    overflow-y: hidden;

    .modalBodyItem {
      margin-bottom: 1rem;
    }
    .selectors {
      p: {
        margin: 14px 0;
      }
      p:first-child {
        margin-top: 0;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modalFooter {
    flex: 1 1 auto;
    color: rgb(50, 49, 48);
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0px 32px 12px 40px;
    text-align: right;

    div {
      display: inline;
    }

    .btnAction {
      margin: 0.1rem;
      padding: 12px 24px 12px 24px;
      height: auto;
    }
  }

  .ModalButton {
    color: rgb(50, 49, 48);
    margin-left: auto;
    margin-top: 4px;
    margin-right: 2px;
  }

  .ModalButton:hover {
    color: rgb(32, 31, 30);
  }

  .ModalFooterRow {
    width: 100%;
    margin-left: 0px;
    flex-flow: inherit;
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
  .activity_details_body {
    overflow-y: auto;
  }

  .headerActivityDetails {
    .history {
      width: 80%;
      padding-left: 15px;
    }
  }
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .activity_details_left {
    // max-height: 95%;
  }

  .headerActivityDetails {
    // height: 10%;
    flex-flow: inherit;
    margin-right: 0px;

    .history {
      width: 100%;
      padding-left: 15px;
    }
  }

  .activity_details_center {
    .ActivityComponents {
      // max-height: 55vh;
    }
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
