// @import 'office-ui-fabric-core/dist/sass/References';
@import '../../../Style/variables.scss';

::placeholder {
  color: $color-grey-dark !important;
  // font: normal normal normal 0.5rem/1rem Arial;  
  letter-spacing: 0.075rem;
}

.RichTextboxCustom {
  min-height: 13rem;
}

.panel-add-activity {
  input[type="file"] {
    display: none;
  }

  .newActivityPopup {
    width: 50%; //50% of window size
  }

  .ms-Grid-col-width100 {
    width: 100% !important;
  }

  .ms-Grid-row {
    margin-bottom: 10px !important;
  }

  .commandFooter {
    float: right;
  }

  .commandFooter button {
    border: none;
  }

  .briefPadding {
    margin-top: 10px !important;
  }

  .messageError {    
    margin-bottom: 10px !important;
  }

  .labelContainer {
    display: inline-flex;
    width: 100%;
    height: 20px;
    margin-bottom: 5px;
  }

  .labelContainer > div:nth-child(1) {
    width: 100%;
  }
  .labelContainer > div:nth-child(2) {
    margin-left: -25px;
    width: 0%;
  }
}

.calendarErrorAlert
{
  color:red;
  font-size:medium;
}

.errorAlert
{
  color: red;
  font-size: medium;
  margin-left: 0.8rem !important;
}

.panelHeader {
  display: flex;
  flex: 1 1 auto;
  font-family: Arial;
  justify-content: space-between;
  color: $color-black;
  align-items: center;
  padding: 12px 12px 12px 12px;
  font-weight: 600;
  font-size: large;
  border-bottom: 1px solid $color-grey;
  padding-left: 48px;

  span {
    letter-spacing: 0.05rem;
    font: normal normal normal 1rem/2rem Arial;
  }

  i {
    color: $color-grey-dark;
  }
}


/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}

@media (min-width: 1366px) and (max-width: 1919px) {
}