$default-font: normal normal 18px/32px Arial;
$default-letter-spacing: 0.18px;


$color-custom-grey : #d0d0d0;

$color-black: #242A33;
$color-grey: #D9DDE3;
$color-grey-dark: #667790;
$color-grey-light: #EFF2F7;
$color-grey-ultra-light: #F7F8FB;
$color-white: #FFFFFF;
$color-blue: #0047CC;
$color-blue-dark: #003EB3;
$color-blue-light: #387CFA;

$color-success: #1BC75D;
$color-warning: #FF9B14;
$color-error: #EA2B41;

$color-draft: #003EB3;
$color-planned: #0098DB;
$color-rework: #D02963;
$color-inprogress : #F36C0B;
$color-approved: #0AAD49;
$color-ready-for-distribution: #006F44;
$color-distribuited : #4D73Ac;
$color-closed: $color-black;

$color-brief-status-draft: $color-blue-light;
$color-brief-status-pending: $color-grey-dark;
$color-brief-status-rejected: $color-error;
$color-brief-status-accepted: $color-success;
$color-brief-status-rework: $color-rework;

$excel: #107c41;
$word: #185abd;  
$onenote:  #7719aa;
$powerpoint: #c43e1c;
$pdf: #fb0000;
$jpg: #ece24e;