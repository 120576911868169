@import "../../../../Style/variables.scss";

$color-custom-grey: $color-custom-grey;

.listActivities {
  overflow: hidden visible;
  div[data-automationid="DetailsHeader"] {
    padding-left: 50px !important;
  }

  .MenuProps {
    padding: 2px 5px;
    // min-width: 10px;
  }

  .divRow {
    display: inline-flex;

    .divSelection {
      line-height: 50px;
      vertical-align: middle;

      label {
        width: 50px;
        height: 2px;
        margin-bottom: 0px;
        vertical-align: middle;
      }

      label.selected {
        background-color: $color-blue;
      }
    }
  }
}
.menuContext {
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
  > img {
    width: 10px;
  }
}

.fileTypesSearch {
  width: 400px;
  color: $color-black;
  // font: $default-font;
  font-size: 0.8rem;
  letter-spacing: $default-letter-spacing;
  opacity: 1;
  margin: 15px;
  border-radius: 5px;
  border-color: $color-custom-grey;
}

.activityTypesSearch {
  width: 200px;
  color: $color-black;
  // font: $default-font;
  font-size: 0.8rem;
  letter-spacing: $default-letter-spacing;
  opacity: 1;
  // margin-bottom: 20px;
  // margin-left: 20px;
  // margin-right: 20px;
  margin: 15px;
  border-radius: 5px;
  border-color: $color-custom-grey;
}

.fileTypeOption {
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  padding: 0px;
  width: 100%;
  min-height: 36px;
  line-height: 1rem;
  height: 0px;
  border: 1px solid transparent;
  border-radius: 0px;
  overflow-wrap: break-word;
  text-align: left;
  color: rgb(102, 119, 144);
  margin: 4px;
  min-width: 120px;

  .iconFileExtension {
    padding-right: 5px;
  }

  // i {
  //   padding-right: 15px;
  // }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0px;
    max-width: 100%;
    // overflow-wrap: break-word;
    margin: 1px;
  }
}

.DetailsCell {
  color: $color-black;
  // font: $default-font;
  font-size: 0.75rem;
  letter-spacing: $default-letter-spacing;
  opacity: 1;
  &--brief {
    @extend .DetailsCell;
    font-weight: bold;
  }

  &--filename {
    @extend .DetailsCell;
    display: flex;
    align-items: center;
    i {
      padding-right: 15px;
    }
  }

  &--filetype {
    @extend .DetailsCell;
    > i {
      padding-right: 5px;
    }
  }

  i {
    font-size: 1rem
  }
}

.iconFilter {
  font-size: 9pt;
}

.divResetFilter {
  text-align: right;
  color: $color-blue;
  font-size: 0.5rem;
}

.DeliverablesRow {
  cursor: default;
}

.DetailsCell {
  div[data-automationid="rangeCalendardContainer"] {
    position: relative;
  }
  div[data-automationid="rangeCalendardLabel"] > div {
    margin: 15px 15px;
  }
}

// i[data-icon-name="WordDocument"]
// {
//   color: $word;
// }

// i[data-icon-name="ExcelDocument"]
// {
//   color: $excel;
// }

// i[data-icon-name="OneNoteLogo"]
// {
//   color: $onenote;
// }

// i[data-icon-name="PowerPointDocument"]
// {
//   color: $powerpoint;
// }

// i[data-icon-name="pdf"]
// {
//   color: $pdf;
// }

// i[data-icon-name="Photo2"]
// {
//   color: $jpg;
// }

.dropDownSearch {
  margin: 15px;
  .ms-Dropdown-container,
  .ms-Dropdown-title {
    border-radius: 5px;
    border-color: $color-custom-grey;
    font-size: 0.7rem;
    color: $color-custom-grey;
  }
}

[role="button"] {
  cursor: default;
}

.modalContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  min-width: 40vw;
  border-radius: 10px;
}

.modalHeader {
  flex: 1 1 auto;
  color: rgb(50, 49, 48);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 12px 0px 24px;
  margin-top: 18px;
}

.modalBody {
  flex: 4 4 auto;
  padding: 24px 32px;
  overflow-y: hidden;
  .selectors {
    p: {
      margin: 14px 0;
    }
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.modalFooter {
  flex: 1 1 auto;
  color: rgb(50, 49, 48);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 50px 12px 40px;
  text-align: right;

  div {
    display: inline;
  }
}

.ModalButton {
  color: rgb(50, 49, 48);
  margin-left: auto;
  margin-top: 4px;
  margin-right: 2px;
}

.ModalButton:hover {
  color: rgb(32, 31, 30);
}

.ModalFooterRow {
  width: 100%;
  margin-left: 0px;
  flex-flow: inherit;
}

.divBtnNewFile {
  min-width: 150px;

  button {
    width: 150px;
  }
}

.Label {
  padding-top: 10px;
}

.fileTypeOption {
  i {
    padding-right: 10px;
    font-size: 0.9rem;
  }
}

.divDeliverableVersionsList {
  // margin-top: 20px;
  // height: 20vh;
  // max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;

  .deliverableVersionsDetailsCell {
    color: black;
    display: inline-flex;
    font-size: 0.6rem;
    .text {
      padding: 0px 10px;
    }
  }

  .iconVersionProps {
    width: 10px;
    height: 10px;
  }

  .DeliverablesVersionsRow {
    cursor: pointer;
  }
}

.imgPreview {
  max-height: 60vh;
  max-width: 100%;
}

.embedPreview {
  min-height: 60vh;
}

.modalContainerPreview {
  width: 70vw;
  height: 100vh;
}

.modalBodyPreview {
  text-align: center;
  min-height: 80%;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
