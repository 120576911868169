@import "../../../../src/Style/variables.scss";

.tooltip-container {
  padding-left: 12;
  padding-top: 8;
  padding-right: 12;
  min-width: 300px;
}

.tooltip-title {
  text-align: left;
  font: normal normal bold 14px/14px Arial;
  letter-spacing: 0.14px;
  color: $color-black;
}

.tooltip-calendar{
  border-radius: 4px;
  height: 20px;
  margin: 6px 0px 6px 0px;
  padding: 0px 4px;
  text-align: center;
  font: normal normal bold 8px/20px Arial;
  letter-spacing: 0px;
  color: #041D52;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: #eee;
  display: inline-block;
}

.tooltip-resources {
  float: left;
}

.tooltip-micro {
  float: right;
  margin-top: 12;
  display: flex;
  align-items: center;
}

.tooltip-macro-separator {
  height: 1;
  width: 100%;
  background-color: $color-grey;
  margin-top: 12;
}

.tooltip-macro {
  margin-top: 10px;
}

.tooltip-separator {
  clear: both;
}

.tooltip-daterange {
  text-align: left;
  font: normal normal normal 12px/16px Arial;
  letter-spacing: 0.12px;
  color: $color-grey-dark;
  padding-top: 8px;
}

.tooltip-state {
  text-align: left;
  font: normal normal bold 14px/14px Arial;
  letter-spacing: 0.14px;
  color: $color-grey-dark;
  padding-left: 12px;
  line-height: 14px;
  float: left;
}

.tooltip-macroinfo {
  text-align: left;
  font: normal normal bold 14px/14px Arial;
  letter-spacing: 0.14px;
  color: $color-black;
  padding-left: 8px;
  float: left;
  line-height: 20px;
}

.tooltip-event-macro {
  border: 1px solid #022466;
  border-radius: 4px;
  height: 20px;
  width: 54px;
  // margin: 6px 0px 6px 12px;
  text-align: center;
  font: normal normal bold 10px/11px Arial;
  letter-spacing: 0px;
  color: #041d52;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 20px;
  float: left;
}

.rbc-event-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-left: 12px;
  border-radius: 4px;
  line-height: 14px;
  // float: left;
}
.back-planned {
  background-color: $color-planned;
}
.back-draft {
  background-color: $color-draft;
}
.back-inprogress {
  background-color: $color-inprogress;
}
.back-rework {
  background-color: $color-rework;
}
.back-approved {
  background-color: $color-approved;
}
.back-ready {
  background-color: $color-ready-for-distribution;
}
.back-distributed {
  background-color: $color-distribuited;
}
.back-closed {
  background-color: $color-closed;
}


/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}