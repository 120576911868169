.links-container
{    
    display: flex;
    justify-content: space-between;
    
    .links-urlinput
    {
        width: 95%;
    }

    .links-addbutton
    {
        width: 5%;
        position: relative;
        bottom: -36px;        
    }
}
