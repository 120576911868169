.file-item
{
    text-align: left;
    font: normal normal normal 18px/28px Arial;
    letter-spacing: 0.36px;
    color: #242A33;
    opacity: 1;
}

.file-name
{
    cursor: pointer;
    i {
        margin-right: 10px;
    }        
}
.footer-file-label
{
    text-align: left;
    font: normal normal normal 18px/28px Arial;
    letter-spacing: 0.36px;
    color: #667790;
    opacity: 1;
}
.footer-file-value
{
    text-align: left;
    font: normal normal bold 18px/28px Arial;
    letter-spacing: 0.18px;
    color: #667790;
    opacity: 1;
    white-space: pre-wrap;
}

.footer-file
{
    border-top: 1px solid grey;
    margin-top: 10px;
}