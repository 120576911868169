@import './variables';
@import './reset';
@import '../../../../../Style/variables.scss';

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  // height: 600px;
  // overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 50px;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: $out-of-range-color;
}

.rbc-off-range-bg {
  background: $out-of-range-bg-color;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  // text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid $cell-border;

  & + & {
    border-left: 1px solid $cell-border;
  }
  .rbc-header-content {
    margin-left: 25px;
    margin-top: 24px;
    height: 170px;    
  }
  .rbc-header-day {
    text-align: left;
    font: normal normal normal 1rem/2rem Arial;
    letter-spacing: 0.02rem;
    color: $color-black;
    opacity: 1;
    text-transform: capitalize;
  }
  .rbc-header-weekDay {
    color: var(--grey-dark-667790);
    text-align: left;
    font: normal normal normal 1rem/1.5rem Arial;
    letter-spacing: 0.36px;
    color: #667790;
    opacity: 1;
    text-transform: capitalize;
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  & > a {
    &, &:active, &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-today-week {
  //background-color: $today-highlight-bg;
  color: #0152E8 !important;
  // border-left: 1px solid #0152E8 !important;
}

.rbc-today-week-selected {
  //background-color: $today-highlight-bg;
  border-left: 1px solid #0152E8 !important;
  border-right: 1px solid #0152E8 !important;
}

  .rbc-today-week span {
    color: #0152E8 !important;
  }

@import './toolbar';
@import './event';
@import './month';
@import './agenda';
@import './time-grid';