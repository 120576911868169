.activityCard {
  max-height: 234px;
  // max-width: 365px;
  height: 234px;
  margin-bottom: 25px;
  padding: 16px 10px 6px 10px !important;
  border: 0;
  box-shadow: 0 13px 34px 0 rgba(1, 13, 38, 0.05);
  background-color: white !important;
  border-radius: 20px;
  box-shadow: 7px 7px 13px #70707024;

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.55rem;
  }

  .card_body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .card_body_header,
  .card_body_footer {
    display: table-cell;
    padding: 10px 10px;
    font-family: Arial;
    color: gray;
  }

  .card_body_content {
    height: 100%;
    padding: 0px 10px;
    font-family: Arial;
    color: black !important;
  }

  .card_title {
    margin-top: 10px;
    font-size: 1.25rem;
    font-weight: 500;
    // height: 2.3em;
  }

  .card_title:hover {
    text-decoration: underline;
  }

  .cardDate {
    // text-align: right;
    // margin-top: 10px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .cardState {
    padding: 0px;
    margin-top: 0px !important;
    margin-bottom: 0;
    padding-left: 0px !important;
    padding-right: 0px !important;
    font-size: 0.70rem;
    font-weight: bold;

    .rbc_event_dot {
      margin-left: 0px;
      margin-right: 10px;
    }
  }

  .dateLabel {
    font-size: 0.6rem;
    // margin-top: 0px;
    // margin-top: 5px;
    margin-bottom: 4px !important;
  }

  .truncate_overflow_parent {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    text-align: left;
    // margin-right: -1em;
    // padding-right: 1em;
    // width: 265px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .truncate_overflow_child {
    cursor: pointer;
    overflow: hidden;
    text-align: left;
    padding-right: 1em;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding: 0px;
    font-weight: 600;
    margin-top: 4px;
    font-size: 0.65rem;
    line-height: 1.2em;
    max-height: 2.4em;
    height: 2.4em;
    margin-bottom: 5px;
  }
}

.activityCard:hover {
  box-shadow: 7px 7px 13px #70707047;
  cursor: pointer;
}

@media (max-width: 767px) {
  .cardState {
    width: 100%;
  }
}

p.card_text {
  white-space: inherit;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.card_text.card_greytext {
  span {
    font-weight: bold;
  }

  span.author {
    color: black;
  }

  span.date {
    font-weight: 600;
  }
}



/* SM */
@media (max-width: 479px) {}

/* SM + MD */
@media (max-width: 639px) {}

/* SM + MD + L */
@media (max-width: 1023px) {
  .cardDate {
    text-align: left;
  }

  .dateLabel {
    margin-top: 10px;
  }
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .cardDate {
    text-align: right;
    flex-direction: column;
    display: flex;
  }

  .dateLabel {
    margin-top: 2px;
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {}

/* XXXL */
@media (min-width: 1920px) {}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {}