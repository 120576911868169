@import '../node_modules/office-ui-fabric-react/dist/css/fabric.css';



.content {
    min-height: 100vh;
  }

body
{
    font-family: Arial, Helvetica, sans-serif;
}

.bodyApp {
    /* background-color: black; */
    height: 100%;    
}

.sticky {
    position: sticky;
    /* width: 100%; */
    top: 0;
    z-index: 200;
  }

  .sticky > div {
    max-height: 80px;
  }

  .btn-scrollTop {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    min-width: 10px;
    border-color: #ffffff;
    background: #0152e8 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 16px #00000029;
    opacity: 1;
    color: #ffffff;
    z-index: 4;
    position: fixed;
    right: 32px;
    bottom: 32px;
  }
  .btn-scrollTop i {
    font-size: 0.75rem;
    margin-left: 10px;
  }
  .btn-scrollTop:hover {
    color: #ffffff;
    border-color: #ffffff;
    background: #0152e8 0% 0% no-repeat padding-box;
  }
  .btn-scrollTop:focus {
    outline: none;
  }

@media (max-width: 479px) {
}

@media (max-width: 639px) {
}

@media (max-width: 1023px) {
}

@media (max-width: 1365px) {
}

@media (max-width: 1919px) {
}

@media (min-width: 640px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1366px) {
}

@media (min-width: 1920px) {
}

@media (min-width: 480px) and (max-width: 639px) {
}

@media (min-width: 640px) and (max-width: 1023px) {
}

@media (min-width: 1024px) and (max-width: 1365px) {
}

@media (min-width: 1366px) and (max-width: 1919px) {
}