@import '../../../../Style/variables.scss';

.listBriefs {
  
  // overflow: hidden visible;
  margin-left: 86px;
  margin-right: 86px;
  // div[data-automationid="DetailsHeader"] {
  //   padding-left: 86px !important;
  // }

  .DetailsRowNoMicro { 
    padding-left: 36px !important;
  }

  .divRow { 
    display: inline-flex;

    .divSelection {
      line-height: 50px;
      vertical-align: middle;

      label {
        width: 50px;
        height: 2px;
        margin-bottom: 0px;
        vertical-align: middle;
      }

      label.selected {
        background-color: blue;
      }
    }

    .divInnerSelection {
      position: absolute;
      left: -50px;
      line-height: 50px;
      vertical-align: middle;

      label {
        width: 50px;
        height: 2px;
        margin-bottom: 0px;
        vertical-align: middle;
      }

      label.selected {
        background-color: blue;
      }
    }
  }
}

.iconProps {
  width: 10px;
  height: 10px;
}

.iconStatus {
  width: 20px;
}

.detailcell-column-title
{
  letter-spacing: 1.12px;
  color: $color-grey-dark;
  text-transform: uppercase;
  opacity: 1;
}

.DetailsCell {
  // font: normal normal normal 12px/18px Arial;
  font-size: 12px;
  letter-spacing: 0.18px;
  color: #242A33;
  opacity: 1;
  cursor: pointer;
}

.DetailsCell--title {
  @extend .DetailsCell;
  text-align: left;
  // font: normal normal bold 12px/28px Arial;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.18px;
  color: #242A33;
  opacity: 1;
}

.typeCell {
  border: 1px solid black;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  padding: 5px;
  margin-right: 5px;
  font-size: 9px;
}

.countCell {
  border: 1px solid grey;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  padding: 5px;
  margin-right: 5px;
  font-size: 9px;
  width: 25px;
}

.notification
{
  padding-top: 5px;
}

.iconFilter {
  font-size: 9pt;
}

.refreshButton
{
  color: $color-blue-dark;
}

.divResetFilter {
  text-align: right;
  color: blue;
  font-size: 10pt;
}

.ActivityRow {
  cursor: pointer;
}

.DetailsCell {
  div[data-automationid=rangeCalendardContainer]{
    position: relative;
  }
  div[data-automationid=rangeCalendardLabel] > div {     
      margin: 15px 15px;    
  }  

}

.status
{
  border: 2px solid;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 15px;
  border-radius: 20px;  
  text-align: left;
  // font: normal normal bold 14px/16px Arial;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0px;

  &[status-type="draft"]
  {
    border-color: $color-brief-status-draft;
    color: $color-brief-status-draft;
  }

  &[status-type="pending"]
  {
    border-color: $color-brief-status-pending;
    color: $color-brief-status-pending;
  }

  &[status-type="rejected"]
  {
    border-color: $color-brief-status-rejected;
    color: $color-brief-status-rejected;
  }

  &[status-type="accepted"]
  {
    border-color: $color-brief-status-accepted;
    color: $color-brief-status-accepted;
  }

  &[status-type="rework"]
  {
    border-color: $color-brief-status-rework;
    color: $color-brief-status-rework;
  }
      
}


.fileTypeOption
{
  display: flex;
  align-items: center;
  position: relative;    
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  padding: 0px;
  width: 100%;
  min-height: 36px;
  line-height: 20px;
  height: 0px;
  border: 1px solid transparent;
  border-radius: 0px;
  overflow-wrap: break-word;
  text-align: left;
  color: rgb(102, 119, 144);
  margin: 4px;
  min-width: 120px;

  .iconFileExtension
  {
    padding-right: 5px;
  }

  // i {
  //   padding-right: 15px;
  // }   

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0px;
    max-width: 100%;
    // overflow-wrap: break-word;
    margin: 1px;
  }

}

.dropDownSearch {
  margin: 15px;  
  .ms-Dropdown-container,
  .ms-Dropdown-title {
    border-radius: 5px;
    border-color: $color-custom-grey;
    font-size: 13px;
    color: $color-custom-grey;
  }
}

.filterContainerReferent 
{
  @extend .filterContainer;
  min-width: 450px;
}

.filterContainer
{
  margin: 15px;
  border-radius: 5px;
  border-color: #d0d0d0;
}

@media (max-width: 1365px) {
  .listBriefs {
    overflow: auto visible;
  }
}

@media (min-width: 1366px) {
  .listBriefs {
    overflow: hidden visible;
  }
}
