.btn-add {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  background: #0152e8 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 16px #00000029;
  opacity: 1;
  color: #ffffff;
  z-index: 4;
  position: fixed;
  right: 32px;
  bottom: 32px;
}
.btn-add i {
  font-size: 30px;
  margin-left: 10px;
}
.btn-add:hover {
  color: #ffffff;
  border-color: #ffffff;
  background: #0152e8 0% 0% no-repeat padding-box;
}
.btn-add:focus {
  outline: none;
}

.panel-add-activity-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 8px;
  border: 1px solid #0152e8;
  padding-top: 24px;
}
.panel-add-activity-buttons-close {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: white;
  height: 24px;
  width: 28px;
  cursor: pointer;
}

.btn-add-activity {
  padding: 0 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 12px #0000001a;
  border: 1px solid #0152e8;
  border-radius: 4px;
  opacity: 1;
  height: 44px;
  width: 104px;
  z-index: 1;
  /* position: fixed; */
  color: var(--blue-0152e8);
  text-align: center;
  font: normal normal normal 14px/32px Arial;
  letter-spacing: 0.28px;
  color: #0152e8;
  opacity: 1;
  z-index: 10;
}
/* .btn-add-activity.macro {

}
*/
.btn-add-activity.micro {
  margin-top: 8px;
}
.btn-add-activity:hover {
  color: #ffffff;
  border-color: #ffffff;
  background: #0152e8 0% 0% no-repeat padding-box;
}
.btn-add-activity:focus {
  outline: none;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}