.action-button {
    background-color: transparent;
    color: white;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    font-size: 16px;
    min-width: 131px;
    min-height: 48px;
    padding-left: 5px;
    padding-right: 5px;
}
    .action-button i {
        font-size: 11px;
    }

    .action-button:hover {
        color: #0047CC;
        border-color: white;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;
    }

.display-none {
    display: none;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}