.activity {
  background-color: #f7f8fb;

  .content {
    .backgroundDiv {
      background-color: #f7f8fb;
      width: 100%;
      // padding: 40px 60px;
    }

    .lowpanel {
      background-color: #f7f8fb;
      width: 100%;

      .panel_title {
        font-size: 2rem;
        font-family: Arial;
        font-weight: bold;
        color: inherit;
      }

      .tab_pnl_home {
        // margin-top: 5px !important;
        margin-right: 0px;
      }
    }

    .view_all {
      text-decoration: underline;
      float: right;
      cursor: pointer;
      color: #0555fa;
    }
  }
}

.noActivitiedFound {
  padding-bottom: 20px;
}

@media (min-width: 767px) {
}

/* Mobile */
@media (max-width: 767px) {
}

.nav_tabs {
  border-bottom: 0;
  cursor: pointer;
  overflow: hidden;

  li a {
    padding: 10px 30px 10px 0px;
  }
}

/* SM */
@media (max-width: 479px) {
  .content {
    .backgroundDiv {
      background-color: #f7f8fb;
      width: 100%;
      padding: 0px;
    }
  }
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
  .margin_top_mobile {
    margin-top: 0px !important;
  }
  .activeState {
    display: inline;
    font-size: 1rem;
    float: left;
  }
  .selState {
    display: inline;
    font-size: 1rem;
    float: right;
  }
  .statePanel {
    display: flow-root;
  }
  .nav_tabs {
    display: block;
    width: 100%;
    //padding-top: 20px;
  }
  .tab_content {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .tab_pnl_home {
    width: 100%;
    min-height: 50px;
  }
  .panel_state {
    display: grid;
    .view_all {
      text-align: right;
      margin-top: 5px;
    }
  }
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

@media (min-width: 480px) {
  .content {
    .backgroundDiv {
      background-color: #f7f8fb;
      width: 100%;
      padding: 40px 60px;
    }
  }
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .tab_pnl_home {
    min-height: 100px;
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
