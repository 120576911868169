@import "../../../../../Style/variables.scss";

.FilterMyActivitiesPivot {
  .ms-Pivot-link {
    height: 40px;
    margin: 0px;
  }

  .ms-Pivot-link::before {
    background-color: #f3f2f1;
    bottom: 0px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
  }

  .ms-Pivot-link.is-selected::before {
    background-color: black;
  }
}

.listactivities-nav-container {
  &.is-sticky {
    position: fixed;
    top: 0;
    padding-top: 95px;
    background-color: white;
    z-index: 2;
    width: 100%;
    padding: 90px 10px 10px;
  }
}

.listactivities-nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1rem;

  // &.is-sticky {
  //   position: fixed;
  //   top: 0;
  //   padding-top: 95px;
  //   background-color: white;
  //   z-index: 2;
  //   width: 100%;
  //   padding: 90px 10px 10px;
  // }
}

.listactivities-nav-filters {
  padding: 10px 0px;

  // &.is-sticky {
  //   position: fixed;
  //   top: 0;
  //   padding-top: 95px;
  //   background-color: white;
  //   z-index: 2;
  //   width: 100%;
  //   padding: 90px 10px 10px;
  // }
}

.listactivities-nav-actions {
  display: flex;
  justify-content: flex-start;

  .listactivities-nav-buttons {
    & button {
      text-align: left;
      font: normal normal bold 1rem/1rem Arial;
      letter-spacing: 0.14px;
      color: #667790;
      border: none;
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      background: none;
      background-image: none;
      padding: 8px 16px;
      border-radius: 4px;
      line-height: normal;
      white-space: nowrap;

      &:active,
      &.btn-active {
        color: #ffffff;
        background: #0152e8 0% 0% no-repeat padding-box;
        border-radius: 4px;

        &:hover,
        &:focus {
          color: #ffffff;
          background: #0152e8 0% 0% no-repeat padding-box;
          border-radius: 4px;
          outline-color: inherit;
        }
      }

      &:focus {
        color: #373a3c;
        background-color: #e6e6e6;
        border-color: #adadad;
      }

      &:hover {
        color: #373a3c;
        background-color: #e6e6e6;
        border-color: #adadad;
      }
    }
  }
}

.listactivities-nav-selector {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  & .nav-selector-label {
    // padding: 0 16px;
    // background: #0152E8 0% 0% no-repeat padding-box;
    // border-radius: 4px;
    // font: normal normal bold 18px/26px Arial;
    // letter-spacing: 0px;
    // color: #FFFFFF;
    // height: 32px;
    // display: flex;
    // align-items: center;
    padding: 4px 16px 4px 16px;
    background: #0152e8 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: center;
    font: normal normal bold 1rem/1.5rem Arial;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
  }

  & button {
    min-width: 50px;
    text-align: left;
    color: #0152e8;
    font: normal normal bold 1rem/1rem Arial;
    letter-spacing: 0.14px;
    border: none;
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    background: none;
    background-image: none;
    padding: 8px;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;

    &:active,
    &.btn-active {
      color: #ffffff;
      background: #0152e8 0% 0% no-repeat padding-box;
      border-radius: 4px;

      &:hover,
      &:focus {
        color: #ffffff;
        background: #0152e8 0% 0% no-repeat padding-box;
        border-radius: 4px;
        outline-color: inherit;
      }
    }

    &:focus {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad;
    }

    &:hover {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
}

.listactivities-nav .showMenuButton {
  padding: 0px 0px;
  min-width: auto;
  border: none;
  background: none;
  margin: 0px;
  // margin-top: -10px;
  i {
    font-size: 1.5rem;
  }
}

.listactivities-nav-showfilters {
  margin-left: auto;
  text-align: end;

  .showFilters {
    min-width: 150px;
  }
}

.listactivities-nav-switch {
  margin-left: 40%;
}

.status-container {
  background-color: $color-white;
  margin: auto;
  margin-left: 5px;
}

.filter-status-container {
  // background-color: $color-white;
  margin: auto;
  margin-left: 5px;
  display: flex;

  .iconFilter {
    font-size: 9pt;
    margin: 0 4pt;
  }
  span {
    font-size: 8pt;
  }
}

.filterHeader {
  font-weight: bold;
  font-size: large;
}

.filterDropdown {
  .ms-Dropdown {
    margin: 0px;
  }
}

.MuiBadge-badge {
  font-size: 0.7rem;
  height: 17px;
  padding: 5px 5px;
  left: 20px;
  min-width: 17px;
}

.circle-status {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

span[status-name="planned"] {
  background-color: $color-planned;
}

span[status-name="draft"] {
  background-color: $color-draft;
}

span[status-name="inprogress"] {
  background-color: $color-inprogress;
}

span[status-name="rework"] {
  background-color: $color-rework;
}

span[status-name="approved"] {
  background-color: $color-approved;
}

span[status-name="readyfordistribution"] {
  background-color: $color-ready-for-distribution;
}

span[status-name="distributed"] {
  background-color: $color-distribuited;
}

span[status-name="closed"] {
  background-color: $color-closed;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
