.month-calendar-container {
    font-family: Arial;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.month-calendar-header {
    text-align: center;
    font-size: 0.6rem;
    color: #667790;;
    padding-top: 8px;
    position: relative;
}

.month-calendar-header:not(.month-calendar-header--has-time-select) {
    border-top-right-radius: 0.3rem;
}
.month-calendar-monthname {
  margin-bottom: 20px;
  text-transform: capitalize;
}

.month-calendar-monthname, .react-datepicker-time__header, .react-datepicker-year-header {
    color: #242A33;
    font: normal normal normal 18px/28px Arial;
    letter-spacing: 0.36px;
}

.month-calendar-monthname a
{
  color: #242A33;
}

.month-calendar-monthname-current
{
  color: #0152E8;
}

.month-calendar-day-header-names {
  margin-bottom: 14px;
}

.month-calendar-day-header-names, .month-calendar-week-container {
    white-space: nowrap;
}
.month-calendar-day-name
{
  display: inline-block;
  width: 32px;
  text-align: center;
  font: normal normal normal 12px/16px Arial;
  letter-spacing: 0.12px;
  color: #667790;
  opacity: 0.4;
  margin: 0 5px;
  text-transform: capitalize;
}

.month-calendar-dayinternal {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font: normal normal normal 18px/28px Arial;
  letter-spacing: 0.36px;
}

.month-calendar-day-number {

}

.month-calendar-day-number a{
  color: #667790;
}

.month-calendar-days-container {
    margin: 0 5px;
    text-align: center;
}

.month-calendar-day {
    color: #667790;
    display: inline-block;
    width: 32px;
    text-align: center;
    margin: 0 5px;
}

.month-calendar-day a{
  color: #667790;
}

.month-calendar-today {

  color: #0152E8;  
  border-radius: 4px;
  border-bottom-width: 0px;
  text-align: center;
  margin: auto;
  font-weight: bold;
}

.month-calendar-selected-day {
  
  border: solid;
  border-color: #0152E8;  
  border-radius: 4px;
  border-width: 2px;
  text-align: center;
}

.month-calendar-day-pointer {
    cursor: pointer;
}

.cicle-container
{
    margin-top: -4px;
    line-height: 8px;
}

.circle-yes-activities {
    height: 8px ; 
    width: 8px ;
    background-color: #0152E8;
    border-radius: 50%;
    display: inline-block;
  }

  .circle-no-activities {
    height: 3px;
    width: 3px;
    background-color: #c5e801;
    border-radius: 50%;
    display: inline-block;
    visibility: hidden;
  }  

  .circle-status
  {
    height: 8px;
    width: 8px;    
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  .back-planned {
    background-color: #0098db;
  }
  .back-draft {
    background-color: #0043ac;
  }
  .back-inprogress {
    background-color: #f36c0b;
  }
  .back-rework {
    background-color: #d02963;
  }
  .back-approved {
    background-color: #0aad49;
  }
  .back-ready {
    background-color: #006f44;
  }
  .back-distributed {
    background-color: #4d73ac;
  }
  .back-closed {
    background-color: #242a33;
  }

  .activityThinDetail
  {
    font-weight: bold;
  }

  .tooltip-activity {
    margin: 16px 5px;
    font: normal normal bold 14px/14px Arial;
    letter-spacing: 0.14px;
    min-width: 250px;
  }