@import "../../../Style/variables.scss";

.PanelBriefHeaderComponent {
  margin: 0px 20px;
  width: 100%;

  .TitleDetails {
    font-size: 20px;
    font-weight: bold;
  }

  .MenuProps {
    padding: 10px 0px;
  }

  .SubTitleDetails {
    color: #6D7D95;
    font-size: 11px;
    letter-spacing: 1.3px;
    font-weight: bold;
  }

  .statuslabelDetails {
    margin: 5px 10px;
  }

  .typeDetails,
  .TitleDetails,
  .statusChangeDetails,
  .contextMenuHeaderDetails {
    padding-right: 5px;
    padding-left: 5px;
  }

  .contextMenuHeaderDetails {
    text-align: center;
    .iconContextMenuDetails {
      height: 15px;
      width: 15px;
      margin: 10px;
    }
  }
}

.statusMargin {
  margin-top: 5px;
}

.statusMarginWithSubtitle {
  margin-top: 15px;
}

.status {
  border: 2px solid;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 15px;
  border-radius: 20px;
  text-align: left;
  font: normal normal bold 14px/16px Arial;
  letter-spacing: 0px;

  &[status-type="draft"] {
    border-color: $color-brief-status-draft;
    color: $color-brief-status-draft;
  }

  &[status-type="pending"] {
    border-color: $color-brief-status-pending;
    color: $color-brief-status-pending;
  }

  &[status-type="rejected"] {
    border-color: $color-brief-status-rejected;
    color: $color-brief-status-rejected;
  }

  &[status-type="accepted"] {
    border-color: $color-brief-status-accepted;
    color: $color-brief-status-accepted;
  }

  &[status-type="rework"]
  {
    border-color: $color-brief-status-rework;
    color: $color-brief-status-rework;
  }
}




/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
  .PanelBriefHeaderComponent {
    .statusChangeDetails {
      width: 100%;
      display: flex;
    }
  }
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .PanelBriefHeaderComponent {
    flex-flow: inherit;

    .TitleDetails {
      width: 100%;
    }

    .statusChangeDetails {
      display: inline-flex;
    }
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
