@import './variables';

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  //padding: $event-padding;
  padding: 9px;
  // background-color: $event-bg;
  // background: #0152E8 0% 0% no-repeat padding-box;
  background: #0152e817 0% 0% no-repeat padding-box;
  // opacity: 0.1;
  border-radius: $event-border-radius;
  color: $event-color;
  cursor: pointer;
  width: 100%;
  text-align: left;

  .rbc-event-internal-div {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #0000001A;
    border-radius: 4px;
    opacity: 1;
    text-overflow: ellipsis;
    width: 100%;
  }
  
  .rbc-event-nmicro {
    height: 20px;
    width: 20px;
    margin: 6px 0px 6px 4px;
    text-align: center;
    font: normal normal bold 10px/11px Arial;
    letter-spacing: 0;
    color: #041D52;
    text-transform: uppercase;
    opacity: 1;
    vertical-align: middle;
    line-height: 20px;
    border: 1px solid #667790;
    opacity: 1;
    float: left;
  }

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    // background-color: darken($event-bg, 10%);
  }

  &:focus {
    outline: 5px auto $event-outline;
  }
}

.rbc-event-label {
  @extend .rbc-ellipsis;
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51,51,51,.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
