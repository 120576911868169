.CustomPageComponent {
  .ActivityDescription {
    // color: blue;
    // font-weight: bold;
    cursor: pointer;
    text-align: justify;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    padding-bottom: 0px;
    margin-bottom: 10px;
    padding-right: 20px;
    width: 100%;
  }

  .editablePage {
    flex-flow: inherit;
    display: inline-flex;
    width: 100%;

    .text {
      width: 100%;
    }
  }

  .editableField {
    // color: blue;
    cursor: pointer;
    flex-flow: inherit;
    display: inline-flex;
    width: 100%;

    .text {
      width: 100%;
    }
  }

  .iconSettings {
    font-size: 20px;
  }
}

.radio_item {
  padding-right: 20px;
}
.source {
  width: 100%;
}

.nestedPanelActivityDetails {
  height: 90vh;
  top: 10vh;
}

.activity_details_separator {
  margin: 1rem -20px;
}

.activity_details_body {
  margin: 10px;
  height: 75vh;
  overflow-y: auto;
}

.descriptionEditor {
  flex-flow: inherit;
  // display: inline-flex;
  width: 100%;
}

.descriptionText {
  height: 40vh;
  .labelDescription {
    padding: 0px;
  }

  .textDescription {
    background-color: white;
    border: 1px #c8d2e0 solid;
    width: 100%;
    // height: auto;
    // height: 40vh;
    overflow-y: auto;
    min-height: 300px;

    div:before {
      border: none;
      max-height: 40vh;
    }
  }

  textarea {
    min-height: 200px;
    max-height: 600px;
    overflow-y: auto !important;
  }
}

.descriptionActions {
  color: blue;
  display: inline-flex;
  text-align: right;
  width: 100%;
  flex-flow: row-reverse;

  i {
    padding: 0px 5px;
    font-size: 15px;
    cursor: pointer;
  }
}

.descriptionEdit {
  color: blue;
  display: inline-flex;
  text-align: right;
  flex-flow: row-reverse;

  i {
    padding: 0px 5px;
    font-size: 0.9rem;
    cursor: pointer;
  }
}

.editableField {
  // color: blue;
  cursor: pointer;
  flex-flow: inherit;
  display: inline-flex;
  width: 100%;

  .text {
    width: 100%;
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
