.ContactSection {
  padding-top: 20px;

  .ContactPart {
    margin-right: 50px;

    .ContactHeading {
      font-weight: bold;
      font-size: 14px;
    }

    .ContactText {
      font-size: 13px;
      padding: 5px 0px;
      font-weight: 500;
    }
  }

  .ReportBugSection {
    display: block;
    display: none;
  }
}

.ModalContainer {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  width: 50%;
  // min-width: 826px;
  border-radius: 5px;

  .MessageBar {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 2;
  }

  .modalHeader {
    flex: 1 1 auto;
    color: rgb(50, 49, 48);
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0px 12px 0px 24px;
    margin-top: 1rem;
  }

  .modalBody {
    flex: 4 4 auto;
    padding: 2rem;
    padding-bottom: 0;
  }

  .ModalButton {
    color: rgb(50, 49, 48);
    margin-left: auto;
    // margin-top: 4px;
    margin-right: 2px;
  }

  .HeaderSection {
    font-family: Arial, sans-serif;
    font-size: 15pt;
    color: rgb(51, 51, 51);
    font-weight: bold;
    font-style: normal;
    text-align: left;
    padding: 5px 5px 5px 0px;
    line-height: 1.2;
    overflow-y: hidden;
  }

  .TabSection {
    font-family: Arial;
    display: flex;
    margin-top: 10px;

    .HeaderTabSection {
      display: inherit;
    }
  }

  .TabField {
    // padding: 10px 30px 10px 0px;
    width: 100%;

    .LabelField {
      font-family: Arial, sans-serif;
      font-size: 10.5pt;
      color: rgb(51, 51, 51);
      font-weight: bold;
      font-style: normal;
      text-align: left;
      padding: 5px 0px;
      line-height: 1.2;
      overflow-y: hidden;
    }
    .TextField {
      background-color: #f8f8f8;
      border: 1px #a8a8a8 solid;
      padding: 5px;
    }

    .TextInputField {
      width: -webkit-fill-available;
      font-family: Arial;
      height: 50px;
      border-color: #a8a8a8;
      padding: 10px 10px;
      font-size: 12px;
      /* border: solid 1px black; */
      resize: none;
      color: black;
    }

    .LabelIncident {
      font-family: Arial, sans-serif;
      font-size: 0.8rem;
      color: rgb(51, 51, 51);
      font-weight: bold;
      font-style: normal;
      text-align: left;
      padding: 5px 0px;
      line-height: 1.2;
      overflow-y: hidden;
    }

    .TextInputIncidentText,
    .TextInputIncidentText::placeholder {
      font-size: 0.8rem;
    }

    .TextInputIncidentText::placeholder {
      color: #a4a4a4;
    }

    .TextInputIncident div {
      font-size: 0.7rem;
      border-radius: 0.4rem;
      border-color: #a4a4a4;
    }

    .TextInputIncident.Error div {
      border-color: red;
    }

    .LabelErrorMessage {
      font-weight: bold;
      color: red;
      font-size: 0.7rem;
    }

    .IconText {
      font-size: 25px;
      cursor: pointer;
      padding: 10px;
    }

    .ButtonField {
      padding: 10px 40px;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid #0f51f9;
      // width: 100%;
      margin: 1rem;
      border-radius: 0.2rem;
      margin-right: 0px;
    }

    @media (min-width: 480px) {
      width: 100%;
    }

    .ButtonField:hover {
      background-color: #003eb3;
      color: white;
    }

    .ButtonSecondaryField {
      background-color: white;
      color: #0f51f9;
    }

    .ButtonPrimaryField {
      background-color: #0f51f9;
      color: white;
      text-decoration: auto;
    }

    .ButtonField:disabled,
    .ButtonPrimaryField:disabled,
    .ButtonSecondaryField:disabled {
      background-color: rgba(239, 239, 239, 0.3);
      color: rgba(16, 16, 16, 0.3);
      border-color: rgba(118, 118, 118, 0.3);
    }
  }

  .TabFieldButton {
    text-align: right;
  }

  .divResult {
    text-align: center;
    margin-bottom: 3rem;
  }

  .IconResultOk {
    font-size: 40px;
    padding-right: 5px;
    margin-top: 3px;
    color: green;

    font-weight: bold;
  }

  .IconResultError {
    font-size: 40px;
    padding-right: 5px;
    margin-top: 3px;
    color: darkorange;
  }

  .MessageResult {
    font-size: 0.9rem;
    padding-right: 5px;
    margin-top: 1rem;
    color: black;
    font-weight: 500;

    .TitleMessage {
      margin: 1rem;
    }

    .SecondaryMessage {
      font-size: 0.8rem;
    }

    .ButtonField {
      padding: 10px 40px;
      font-size: 0.8rem;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid #0f51f9;
      // width: 100%;
      margin: 1rem;
      border-radius: 0.2rem;
      margin-right: 0px;
      margin: 2rem 0;
    }

    @media (min-width: 480px) {
      width: 100%;
    }

    .ButtonField:hover {
      background-color: #003eb3;
      color: white;
    }

    .ButtonSecondaryField {
      background-color: white;
      color: #0f51f9;
    }

    .ButtonPrimaryField {
      background-color: #0f51f9;
      color: white;
      text-decoration: auto;
    }
  }
}

@media (min-width: 940px) {
  .ContactSection {
    display: inline-flex;
  }
}

@media (max-width: 939px) {
  .ContactSection {
    padding-bottom: 20px;
    display: grid;
  }
}

@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
  .ModalContainer {
    width: 90%;
  }

  .TabFieldInline {
    .TabFieldLeft {
      padding: 10px 0px;
    }

    .TabFieldRight {
      padding: 5px 0px;
    }
  }
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
  .TabFieldInline {
    display: inline-flex;

    .TabFieldLeft {
      padding: 10px 15px 10px 0;
    }

    .TabFieldRight {
      padding: 10px 0px 10px 15px;
    }
  }
  .ModalContainer {
    width: 50%;
  }
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
