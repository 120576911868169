@import '../../../Style/variables.scss';

::placeholder {
  color: $color-error;
}

.panel-add-activity {
  .ms-Grid-col-width100 {
    width: 100% !important;
  }

  .commandFooter {
    float: right;
  }

  .commandFooter button {
    border: none;
  }

  .macroPadding {
    margin-top: 10px !important;
  }
}
.microErrorMessage
{
  color:red;
  font-size:medium;
}

.panelHeader {
  display: flex;
  flex: 1 1 auto;
  font-family: Arial;
  justify-content: space-between;
  color: $color-black;
  align-items: center;
  padding: 12px 12px 12px 12px;
  font-weight: 600;
  font-size: large;
  border-bottom: 1px solid $color-grey;
  padding-left: 48px;

  i {
    color: $color-grey-dark;
  }
}

.RichTextboxCustom {
  min-height: 13rem;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
