@import '../../../../../src/Style/variables.scss';

.calendar-row {        
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}