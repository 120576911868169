.panel
{
    height: 90vh;
    top: 10vh;    
    /* width: 25vw; */
    /* background-color: #f7f8fb; */
}

.notify .notify-button {
    background-color: transparent;
    border-color: transparent;
    color: rgb(243 242 241 / 60%);
}

.notify .notify-button:hover {
    background-color: white;
    color: #0047CC;
    border-color: white;
}

.notify .notify-to-read
{    
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #FF0F64;
    position: relative;
    top: -26px;
    left: 19px;
}

.notify .notify-button i {
    font-size: 24px;
} 
 
 .notification-group
{
    width: 386px;
    background: #fff;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    
    /* max-height: 600px; */
}

.notificationew-content
{
    width: 283px;
    margin-right: 16px;
}

/* .notification-iconstatus { grid-area: iconStatus; }
.notification-content { grid-area: content; }
.notification-iconclose { grid-area: close; } */

.notificationnew-status-info {
    background-color: white;    
    color: #666790;
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.notificationnew-close {
    background-color: white;    
    color: #666790;
    width: 24px;
    height: 24px;
}

.notificationnew-container {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    padding-bottom: 10px;
}
  
.notificationnew-container:hover {    
opacity: 1;
cursor: pointer;
}

.notificationnew-iconstatus
{    
    margin: auto;
}
  
.notificationnew-title {
    font: normal normal bold 12px/14px Arial;
    letter-spacing: 0.12px;
    color: #242A33;
    text-transform: uppercase;
    opacity: 1
}

.notificationnew-user {
    text-align: left;
    font: normal normal bold 12px/16px Arial;
    letter-spacing: 0.12px;
    color: #667790;
    padding-right: 4px;
}

.notificationnew-message {
    text-align: left;
    font: normal normal normal 12px/16px Arial;
    letter-spacing: 0.12px;
    color: #667790;
}
