.DescriptionActivityViewComponent {

  display: inline-flex;
  min-width: 100%;


  .activity_details_parent_body {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
    min-width: 100%;
  }

  .label_ActivityAttribute {
    color: #101AA1;
    font-weight: 500;
    padding: 10px 0px;
  }

  .value_ActivityAttribute {
    color: black;
    padding: 0px 0px 10px 0px;
    width: 100%;
    flex-flow: inherit;
    // display: inline-flex;
  }

  .assignedResources {
    width: 100%;
  }

  .rbc_event_dot {
    margin-top: 7px;
  }

  .descriptionEditor {
    flex-flow: inherit;
    display: inline-flex;
    width: 100%;
  }

  .descriptionText {
    display: block;

    // height: 40vh;
    .labelDescription {
      padding: 0px;
      flex: 1 1 100%;
    }

    .textDescription {
      // padding: 0px 10px;
      background-color: white;
      border: 1px #c8d2e0 solid;
      width: 100%;
      // height: auto;
      // height: 40vh;
      overflow-y: auto;
      min-height: 300px;

      div:before {
        border: none;
        max-height: 40vh;
      }

      textarea {
        min-height: 200px;
        max-height: 600px;
        overflow-y: auto !important;
      }
    }
  }

  .descriptionActions {
    color: blue;
    display: inline-flex;
    text-align: right;
    width: 100%;
    flex-flow: row-reverse;

    i {
      padding: 0px 5px;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }

  .descriptionEdit {
    color: blue;
    display: inline-flex;
    text-align: right;
    flex-flow: row-reverse;

    i {
      padding: 0px 5px;
      font-size: 0.5trm;
      cursor: pointer;
    }
  }

}


/* SM */
@media (max-width: 479px) {}

/* SM + MD */
@media (max-width: 639px) {}

/* SM + MD + L */
@media (max-width: 1023px) {}

/* SM + MD + L + XL */
@media (max-width: 1365px) {}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {}

/* XXXL */
@media (min-width: 1920px) {}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {}