.modalContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  min-width: 50vw;
  border-radius: 10px;
}

.searchBoxDiv {
  display: flex;
}

.modalHeader {
  flex: 1 1 auto;
  color: rgb(50, 49, 48);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 12px 0px 24px;
  margin-top: 18px;
}

.modalBody {
  flex: 4 4 auto;
  padding: 24px 32px;
  overflow-y: hidden;
  .selectors {
    p: {
      margin: 14px 0;
    }
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.modalFooter {
  flex: 1 1 auto;
  color: rgb(50, 49, 48);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 50px 12px 40px;
}

.ModalButton {
  color: rgb(50, 49, 48);
  margin-left: auto;
  margin-top: 4px;
  margin-right: 2px;
}

.ModalButton:hover {
  color: rgb(32, 31, 30);
}

.divManageResourcesComponent {
  display: inline-flex;
  flex-flow: inherit;
  width: 100%;

  .listResources {
    // min-width: 70%;
    width: 100%;
    cursor: pointer;
  }

  div.add {
    margin: 8px 0px;
    padding: 0px 0px;
    background-color: #f4f4f4;
    color: blue;
    font-weight: bold;
    font-size: 12pt;
    line-height: 18px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    text-align: center;
  }
}

.ModalFooterRow {
  width: 100%;
  margin-left: 0px;
}

.btn:focus {
  box-shadow: none;
}

.btn-dismiss {
  background-color: transparent;
  border-color: transparent;
  color: #0047cc;
}

.btn-primary {
  background-color: #0047cc;
  border-color: #0047cc;
  color: white;
}

.divBtnSendInvitation {
  min-width: 150px;

  button {
    width: 150px;
  }
}

.addResources.maximized,
.resources.maximized {
  height: 40vh;
}

.addResources,
.resources {
  display: inline-flex;
  width: 100%;
  height: 20vh;
  margin-top: 10px;

  .ms-Viewport {
    width: 100%;
    // height: 200px;
  }

  div.ResourcesCell {
    color: black;
    font-weight: bold;
    line-height: 40px;
    vertical-align: middle;
  }

  div.add {
    margin: 10px 0px;
    padding: 2px 5px;
    background-color: #f4f4f4;
    font-weight: bold;
    font-size: 8pt;
    width: 20px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;

    .Accept {
      color: #1bc75d;
    }

    .Cancel {
      color: #ea2b41;
    }

    .Add {
      color: blue;
    }

    i {
      font-weight: bolder;
    }
  }
}

.ModalFooterRow {
  flex-flow: inherit;
}

.divAssignments {
  // display: grid;
  width: 100%;
}

.labelNewAssignments {
  color: #3ed076;
  padding-right: 20px;
}

.labelremovedAssignemnts {
  color: #ea2b41;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
