
.MicroActivityViewComponent {
  
  .activity_details_parent_body {
    margin-left: 10px;
    margin-right: 50px;
    margin-bottom: 50px;
  }
  
  .label_ActivityAttribute {
    color: #101AA1;
    font-weight: 500;
    padding: 10px 0px;
  }

  .value_ActivityAttribute {
    color: black;
    padding: 0px 0px 10px 0px;
    width: 100%;
    flex-flow: inherit;
    // display: inline-flex;
  }

  .assignedResources {
    width: 100%;
  }

  .rbc_event_dot {
    margin-top: 7px;
  }

}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
