@import './variables';

$active-background: darken($btn-bg, 10%);
$active-border: darken($btn-border, 12%);

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;

  .rbc-toolbar-label {
    flex-grow:1;
    padding: 0 10px;
    text-align: center;
    text-transform: capitalize;
  }
  .rbc-toolbar-label .btn-back .ms-Icon, .rbc-toolbar-label .btn-next .ms-Icon {
    color: #0152E8;
  }
  .rbc-toolbar-label button {
    color: #0152E8;
    border: none;
    height: 34px;
    margin: 0 7px 10px 7px !important;
  }
  .rbc-toolbar-label button:focus {
    outline: none;
  }
  .rbc-toolbar-label .selection {
    padding: 9px 16px 5px 16px;
    background: #0152E8 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: center;
    font: normal normal bold 18px/26px Arial;
    letter-spacing: 0px;
    color: #FFFFFF;
    
  }

  & button, .rbc-toolbar-label button {
    text-align: left;
    font: normal normal bold 14px/14px Arial;
    letter-spacing: 0.14px;
    color: #667790;
    border: none;
    // color: $btn-color;
    display: inline-block;
    margin: 0;
    //text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    //border: 1px solid $btn-border;
    padding: 8px 16px;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;

    &:active,
    &.rbc-active {
      // background-image: none;
      // box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      // background-color: $active-background;
      // border-color: $active-border;
      color: #FFFFFF;
      background: #0152E8 0% 0% no-repeat padding-box;
      border-radius: 4px;

      &:hover,
      &:focus {
        // color: $btn-color;
        // background-color: darken($btn-bg, 17%);
        // border-color: darken($btn-border, 25%);
        color: #FFFFFF;
        background: #0152E8 0% 0% no-repeat padding-box;
        border-radius: 4px;
        outline-color: inherit;
      }
    }

    &:focus {
      color: $btn-color;
      background-color: $active-background;
      border-color: $active-border;
    }

    &:hover {
      color: $btn-color;
      background-color: $active-background;
          border-color: $active-border;
    }
  }
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;

  > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rbc-rtl & > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rbc-rtl & > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  button + button {
    margin-left: -1px;
  }

  .rbc-rtl & button + button {
    margin-left: 0;
    margin-right: -1px;
  }

  & + &,
  & + button {
    margin-left: 10px;
  }
}
