@import '../../../../Style/variables.scss';

.DeliverableComponent {
  .headerDeliverables {
    display: inline-flex;
    // margin-top: 30px;

    span {
      padding-right: 20px;
      font-weight: 500;
      line-height: 2rem;
      font-size: 1.5rem;
    }

    strong {
      padding: 0 0.5rem;
      // margin: 10px;
      background-color: #f4f4f4;
      color: blue;
      font-size: 1rem;
      line-height: 2rem;
      cursor: pointer;
    }
  }

  .divDeliverablesList {
    // margin-top: 20px;
    // height: 20vh;
    // max-height: 20vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5px;

    .deliverablesDetailsCell {
      color: black;
      display: inline-flex;
      font-size: 1.5rem;
      .text {
        padding: 0 0.5rem;
      }
    }
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
  .DeliverableComponent {
    div[data-automationid="DetailsList"] {
      div[role="grid"] {
        overflow-x: auto;
      }
    }
  }
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
  .DeliverableComponent {
    div[data-automationid="DetailsList"] {
      div[role="grid"] {
        overflow-x: hidden;
      }
    }
  }
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
