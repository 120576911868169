@import "../../../../Style/variables.scss";

.input48px::-webkit-input-placeholder,
.input48px::placeholder,
.input48px::-moz-placeholder {
  line-height: 40px;
  font-size: 18px;
}

.briefdetail-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.scroll-y {
  overflow-y: scroll;
  padding-bottom: 50px;
  height: 80vh;
}

.briefdetail-container-item-left {
  flex: 80%;
  text-align: left;

  .line-break {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .default-container {
    margin-bottom: 20px;
  }

  .main-title {
    text-align: left;    
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    color: #667790;
    text-transform: uppercase;
    opacity: 1;
  }

  .main-content {
    text-align: left;
    font-size: 28px;
    font-weight: bold;    
    letter-spacing: 0.28px;
    color: #242a33;
    opacity: 1;
  }

  .default-title {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    color: #667790;
    text-transform: uppercase;
    opacity: 1;
  }

  .default-content {
    text-align: left;
    font-size: 16px;    
    letter-spacing: 0.18px;
    color: #242a33;
    opacity: 1;
  }

  .boolean-text {
    background: #eff2f7 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    text-align: left;
    font-size: 18px;    
    letter-spacing: 0.18px;
    color: #242a33;
  }
}

.briefdetail-container-item-right {
  flex: 20%;
  text-align: right;
  // position: relative;
  // top: 0px;
  // position: -webkit-sticky;

  // position: sticky;
  // top: 52px;
  // max-height: calc(100vh - 52px);
}

.briefdetail-button-top {
  margin-top: 30px;
  margin-bottom: 15px;
}

.briefdetail-buttons {
  > div {
    margin: 15px 0 15px 0;
  }
}

.row-space {
  margin-bottom: 10px !important;
}

.historyContainer {
  margin-left: 30px;
  // margin-right: 10px;
  // overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  // max-height: calc(35vh - 10px);

  .historyTitle {    
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    color: #667790;
    text-transform: uppercase;
    opacity: 1;
  }
}

.statusContainer {
  margin-left: 30px;
  margin-right: 10px;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: left;

  .statusTitle {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    color: #667790;
    text-transform: uppercase;
    opacity: 1;
  }

  .statusMargin {
    margin-top: 10px;
  }
}

.status {
  border: 2px solid;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 15px;
  border-radius: 20px;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;

  &[status-type="draft"] {
    border-color: $color-brief-status-draft;
    color: $color-brief-status-draft;
  }

  &[status-type="pending"] {
    border-color: $color-brief-status-pending;
    color: $color-brief-status-pending;
  }

  &[status-type="rejected"] {
    border-color: $color-brief-status-rejected;
    color: $color-brief-status-rejected;
  }

  &[status-type="accepted"] {
    border-color: $color-brief-status-accepted;
    color: $color-brief-status-accepted;
  }

  &[status-type="rework"] {
    border-color: $color-brief-status-rework;
    color: $color-brief-status-rework;
  }
}

.modalContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  min-width: 40vw;
  border-radius: 10px;
}

.modalHeader {
  flex: 1 1 auto;
  color: rgb(50, 49, 48);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 12px 0px 24px;
  margin-top: 18px;
}

.modalBody {
  flex: 4 4 auto;
  padding: 24px 25px;
  overflow-y: hidden;
  .selectors {
    p: {
      margin: 14px 0;
    }
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.modalFooter {
  flex: 1 1 auto;
  color: rgb(50, 49, 48);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 50px 12px 40px;
  text-align: right;

  div {
    display: inline;
  }
}

.ModalButton {
  color: rgb(50, 49, 48);
  margin-left: auto;
  margin-top: 4px;
  margin-right: 2px;
}

.ModalButton:hover {
  color: rgb(32, 31, 30);
}

.ModalFooterRow {
  width: 100%;
  margin-left: 0px;
  flex-flow: inherit;
}

.modalBodyPreview {
  // text-align: center;
  min-height: 80%;
}

.RichTextboxCustom {
  min-height: 200px;
}

.divValue {
  padding: 10px 0px;
}

.actionInfo {
  background-color: #eff2f7;
  padding: 5px 24px 5px 36px;
  margin: 10px 0px;

  .dotInfo {
    background-color: #fe1e6d;
    display: inline-block;
    height: 8px;
    width: 8px;
    //margin-left: 0px !important;
    margin-right: 5px;
    border-radius: 4px;
  }
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
  .modalContainerPreview {
    width: 100vw;
    height: 100vh;
  }
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
  .modalContainerPreview {
    width: 700px;
    height: 90vh;
  }
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}

.panelHeader {
  display: flex;
  flex: 1 1 auto;
  font-family: Arial;
  justify-content: space-between;
  color: $color-black;
  align-items: center;
  padding: 12px 12px 12px 12px;
  font-weight: 600;
  font-size: large;
  border-bottom: 1px solid $color-grey;
  padding-left: 48px;

  span {
    letter-spacing: 0.28px;
    font-size: 28px;    
  }

  i {
    color: $color-grey-dark;
  }
}

.panelBody {
  flex: 4 4 auto;
  padding: 18px 48px 18px 48px;
  overflow-y: hidden;

  selectors: {
    p: {
      margin: 14px 0;
    }
    //   p:first-child: { marginTop: 0; };
    //   p:last-child: { marginBottom: 0; };
  }

  .panelTitle {
    text-align: left;    
    font-size: 32px;    
    letter-spacing: 0px;
    color: #242a33;
    opacity: 1;
  }

  .panelContent {
    margin: 20px 0 20px 0;
    text-align: left;
    font-size: 18px;    
    letter-spacing: 0.18px;
    color: #242a33;
    opacity: 1;
  }

  .panelButtons {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: right;
    margin-right: -4px;
    font-size: 0px;

    > span {
      margin: 0px 4px;
    }
  }
}
