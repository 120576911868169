@import "../../../Style/variables.scss";

.calendardContainer {
  width: 100%;
}

.ms-DatePicker-day--highlighted {
  background-color: #64696852 !important;
}

.toggleTime {
  padding-left: 10px;
  margin-bottom: 0px;
  margin-top: 3px;

  label {
    font-size: small;
  }
}

.rangeCalendardContainer {
  background-color: $color-blue-light;
  display: grid;
  grid-template-columns: auto auto;
  position: absolute;
  cursor: pointer;
  z-index: 10000000;
  background-color: $color-white;
  border: thin solid $color-grey;
  margin-top: 2px;
  > div {
    text-align: center;
  }
}

.rangeCalendard-toolbar {
  grid-column: 1 / span 2;
  text-align: right !important;
  margin-top: -5px;
  border-top: solid thin $color-grey-light;

  div:nth-child(1) {
    float: left;
  }
  div:nth-child(2) {
    float: right;
  }
}

.rangeCalendardContainer [type="text"] {
  cursor: pointer;
  font-size: small !important;
}
.ms-DatePicker-wrap {
  margin: -1px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.timeContainer {
  font-size: xx-small;
  padding-left: 2px;
  padding-right: 19px;
  display: flex;
  flex-wrap: nowrap;
  background-color: $color-white;
  width: 100%;
}

.timeContainer > div {
  background-color: $color-white;
  margin: auto;
  text-align: center;
}

.timeContainer > div:nth-child(1) {
  width: 40%;
  text-align: left;
}
.timeContainer > div:nth-child(2) {
  width: 5%;
}
.timeContainer > div:nth-child(3) {
  width: 10%;
}
.timeContainer > div:nth-child(4) {
  width: 5%;
}

/* SM */
@media (max-width: 479px) {
}

/* SM + MD */
@media (max-width: 639px) {
}

/* SM + MD + L */
@media (max-width: 1023px) {
}

/* SM + MD + L + XL */
@media (max-width: 1365px) {
}

/* SM + MD + L + XL + XXL */
@media (max-width: 1919px) {
}

/* MD + L + XL + XXL + XXXL */
@media (min-width: 640px) {
}

/* L + XL + XXL + XXXL */
@media (min-width: 1024px) {
}

/* XL + XXL + XXXL */
@media (min-width: 1366px) {
}

/* XXXL */
@media (min-width: 1920px) {
}

/* MD */
@media (min-width: 480px) and (max-width: 639px) {
}

/* L */
@media (min-width: 640px) and (max-width: 1023px) {
}

/* XXL */
@media (min-width: 1024px) and (max-width: 1365px) {
}
