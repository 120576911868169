@import '../../../../../Style/variables.scss';
@import './variables';

.rbc-event-nobackground {
  background: transparent !important;
}

.rbc-event-macro{
  border: 1px solid #022466;
  border-radius: 4px;
  height: 20px;
  width: 54px;
  margin: 6px 0px 6px 12px;
  text-align: center;
  font: normal normal bold 10px/11px Arial;
  letter-spacing: 0px;
  color: #041D52;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 20px;
  float: left;
}

.rbc-event-calendar{
  border-radius: 4px;
  height: 20px;
  margin: 6px 0px 6px 6px;
  padding: 0px 4px;
  text-align: center;
  font: normal normal bold 8px/20px Arial;
  letter-spacing: 0;
  color: #041D52;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: #eee;
  float: left;
}

.rbc-event-dot{
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-left: 12px;
  border-radius: 4px;
  line-height: 0.85rem;
}
.back-planned {
  background-color: $color-planned;
}
.back-draft {
  background-color: $color-draft;
}
.back-inprogress {
  background-color: $color-inprogress;
}
.back-rework {
  background-color: $color-rework;
}
.back-approved {
  background-color: $color-approved;
}
.back-ready {
  background-color: $color-ready-for-distribution;
}
.back-distributed {
  background-color: $color-distribuited;
}
.back-closed {
  background-color: $color-closed;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .rbc-timeslot-group {
    flex: 1;
  }
}


.rbc-timeslot-group {
  border-bottom: 1px solid $cell-border;
  min-height: 50px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}
.rbc-time-vertical{
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  text-align: center;
  font: normal normal normal 12px/16px Arial;
  letter-spacing: 0.12px;
  color: $color-grey-dark;
}

.rbc-day-slot {
  position: relative;

  .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    /*margin-right: 10px;*/
    top: 0;

    &.rbc-rtl {
      left: 10px;
      right: 0;
    }
  }

  .rbc-event {
    // border: 1px solid $event-border;
    border: none;
    display: flex;
    max-height: 100%;
    min-height: 30px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
  }

  .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto;
  }

  .rbc-event-content {
    // width: 100%;
    // flex: 1 1 0;
    // word-wrap: break-word;
    // line-height: 1;
    // height: 100%;
    // min-height: 1em;
    display: inline-block;
    padding: 8px 8px 8px 12px;
    text-align: left;
    font: normal normal bold 0.85rem/0.85rem Arial;
    letter-spacing: 0.01rem;
    color: $color-black;
    opacity: 1;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rbc-event-micro {
    margin-top: 8px;
  }

  .rbc-time-slot {
    border-top: 1px solid lighten($cell-border, 10%);
  }
}

.rbc-time-view-resources {
  .rbc-time-gutter,
  .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid $cell-border;
    z-index: 10;
    margin-right: -1px;
  }

  .rbc-time-header {
    overflow: hidden;
  }

  .rbc-time-header-content {
    min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px;
  }

  .rbc-time-header-cell-single-day {
    display: none;
  }

  .rbc-day-slot {
    min-width: 140px;
  }

  .rbc-header,
  .rbc-day-bg {
    width: 140px;
    // min-width: 0;
    flex:  1 1 0;
    flex-basis: 0 px;
  }
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;

  &.rbc-now {
    font-weight: bold;
  }
}

.rbc-day-header {
  text-align: center;
}
