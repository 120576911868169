@import "../../../Style/variables.scss";

.dashboard {
  width: 100%;
}

.rowDashboard {
  background-color: #f7f8fb;
}

.dashboard .banner {
  background-color: #0152e8;
  color: white;
}

.dashboard .activity {
  background-color: #f7f8fb;
}

.dashboard .activity .content {
  height: 200px;
}

.brief-new-message {
  width: 100%;
  padding: 50px 50px 20px 150px;
  vertical-align: middle;
}

.brief-new-message h1 {
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.brief-new-message div {
  text-align: left;
  font: normal normal 300 20px/28px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 30px;
}

.dashboard_view_all {
  text-decoration: underline;
  float: right;
  cursor: pointer;
  color: #0555fa;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
}

@media (max-width: 1200px) {
  .brief-new-message h1 {
    font-size: 35px;
  }
}

@media (min-width: 1200px) {
  .brief-new-message h1 {
    font-size: 48px;
  }
}

.brief-button {
  padding-left: 50px;
  padding-bottom: 50px;
}

.brief-container {
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 40px;
}

.welcome {
  padding-top: 90px;
  padding-left: 102px;
  margin: 8px;
  height: 380px;
  vertical-align: middle;
}

.welcome h1 {
  font-size: 48px;
}

.request-status {
  padding-top: 74px;
  font-size: 22px;
}
.dashboard_backgroundDiv > .div_tabs {
  border-bottom: 1px solid #dee2e6;
}
.dashboard_backgroundDiv {
  background-color: #f7f8fb;
  width: 100%;
  padding: 40px 40px 65px 30px;
}

.dashboard_nav_tabs {
  border-bottom: none;
  cursor: pointer;
}

.reverse > li {
  float: left;
}

.btn-dashboard-add-activity
// .btn-dashboard-add-activity:hover,
// .btn-dashboard-add-activity.is-expanded 
 {
  background-color: #0152e8;
  color: white;
  border-color: white;

  i
  // i:hover,
  // i.is-expanded 
 {
    color: white !important;
    background-color: #0152e8 !important;
  }
}

.btn-dashboard-add-activity-contextMenu {
  li:nth-child(even) {
    background-color: #eff2f7;
  }

  li:nth-child(odd) {
    background-color: white;
  }

  li button:hover {
    background-color: gray;
  }
}

.btn-dashboard-add-activity2 {
  padding: 0 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 12px #0000001a;
  border: 1px solid #0152e8;
  border-radius: 4px;
  opacity: 1;
  height: 44px;
  width: 104px;
  z-index: 1;
  position: relative;
  margin-left: 0.5rem;
  color: var(--blue-0152e8);
  text-align: center;
  font: normal normal normal 0.8rem/2rem Arial;
  letter-spacing: 0.28px;
  color: #0152e8;
  opacity: 1;
  z-index: 10;
}
.btn-dashboard-add-activity:hover {
  color: #ffffff;
  border-color: #ffffff;
  background: #0152e8 0% 0% no-repeat padding-box;
}
.btn-dashboard-add-activity:focus {
  outline: none;
}
